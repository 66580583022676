import React, { useState, useEffect, useCallback, useContext } from 'react'
import Helmet from 'react-helmet'
import moment from 'moment'
import locale from 'antd/es/date-picker/locale/pt_BR'
import AuthContext from 'contexts/auth'

// Material UI
import {
  Row,
  Col,
  InputNumber,
  Divider,
  PageHeader,
  Table,
  Space,
  Button,
  ConfigProvider,
  Input,
  Dropdown,
  Menu,
  Modal,
  Typography,
  Upload,
  message,
  DatePicker,
  Form,
  Select,
  Descriptions
} from 'antd'
import {
  PaperClipOutlined,
  ExclamationCircleOutlined,
  EllipsisOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
  InboxOutlined,
  EditOutlined
} from '@ant-design/icons'

import { MenuActions, ActionItem } from 'styles/global'

// Componentes
import api from 'services/api'
import TableEmpty from 'components/TableEmpty'
import { formatarMoeda } from 'services/utils'

const { Text, Title } = Typography
const { Dragger } = Upload
const { confirm } = Modal
const { TextArea } = Input

function Prestacao() {
  const selectionType = 'checkbox'
  const route = 'despesa'
  const { user } = useContext(AuthContext)
  const [form] = Form.useForm()
  const [formCat] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [load, setLoad] = useState(false)
  const [selectedData, setSelectedData] = useState([])
  const [details, setDetails] = useState(null)
  const [despesas, setDespesas] = useState([])
  const [filterDespesas, setFilterDespesas] = useState([])
  const [value, setValue] = useState('')
  const [novaDespesa, setNovaDespesa] = useState(false)
  const [newCat, setNewCat] = useState(false)
  const [viewDetail, setViewDetail] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [categorias, setCategorias] = useState([])
  const [attach, setAttach] = useState([])
  const [viewAttach, setViewAttach] = useState(false)
  const [modalAttach, setModalAttach] = useState(false)

  const upload = {
    name: 'file',
    multiple: true,
    action: `${process.env.REACT_APP_API}/upload`,
    method: 'POST',
    data: { pasta: 'despesa' },

    onChange(info) {
      const { status } = info.file
      if (status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (status === 'done') {
        message.success(`${info.file.name} sucesso ao fazer upload.`)
        setAttach(
          info.fileList.map(item => {
            return { anexo: item.response.url }
          })
        )
      } else if (status === 'error') {
        message.error(`${info.file.name} falha ao fazer upload.`)
      }
    }
  }

  // Listar atas
  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true)
        await refreshData()
        api.get(`/categoria?conselhoId=${user.conselhoId}`).then(({ data }) => {
          setCategorias(data)
        })
      } catch {
        message.error('Erro ao carregar os dados, atualize a página')
      } finally {
        setLoading(false)
      }
    }
    loadData()
  }, [load])

  const refreshData = useCallback(async () => {
    await api
      .get(`/${route}?conselhoId=${user.conselhoId}`)
      .then(({ data }) => {
        setDespesas(data)
        setFilterDespesas(data)
      })
  }, [])

  const deleteData = id => {
    confirm({
      title: 'Você deseja realmente excluir?',
      icon: <ExclamationCircleOutlined />,
      content: 'Essa ação é irreversível após a confirmação',
      okText: 'Confirmar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk: async () => {
        await api
          .delete(`/${route}/${id}`)
          .then(() => {
            refreshData()
            setViewDetail(false)
          })
          .catch(() => {
            message.error('Erro ao excluir essa Ata')
          })
      }
    })
  }

  const storeData = () => {
    form.setFieldsValue({
      ...form,
      anexos: attach
    })
    form.validateFields().then(async values => {
      editMode
        ? await api
            .put(
              `/${route}/${details.id}?conselhoId=${user.conselhoId}`,
              values
            )
            .then(() => {
              refreshData()
              setNovaDespesa(false)
              message.success('Despesa editada com sucesso!')
            })
            .catch(e => {
              if (e.response.data) {
                if (e.response.data.errors) {
                  e.response.data.errors.forEach(e => message.error(e.message))
                } else {
                  message.error(e.response.data.error)
                }
              } else {
                message.error('Erro ao editar, tente novamente')
              }
            })
        : await api
            .post(`/${route}?conselhoId=${user.conselhoId}`, values)
            .then(() => {
              message.success('Despesa cadastrada com sucesso!')
              refreshData()
              setNovaDespesa(false)
              form.resetFields()
              setAttach('')
            })
            .catch(e => {
              if (e.response.data) {
                if (e.response.data.errors) {
                  e.response.data.errors.forEach(e => message.error(e.message))
                } else {
                  message.error(e.response.data.error)
                }
              } else {
                message.error('Erro ao cadastrar, tente novamente')
              }
            })
    })
  }

  const storeCat = async () => {
    await formCat.validateFields().then(async values => {
      api
        .post(`/categoria?conselhoId=${user.conselhoId}`, values)
        .then(() => {
          message.success('Categoria cadastrada com sucesso!')
          setNewCat(false)
          formCat.resetFields()
          setLoad(!load)
        })
        .catch(e => {
          if (e.response.data) {
            if (e.response.data.errors) {
              e.response.data.errors.forEach(e => message.error(e.message))
            } else {
              message.error(e.response.data.error)
            }
          } else {
            message.error('Erro ao cadastrar, tente novamente')
          }
        })
    })
  }

  const rowSelection = {
    onChange: selectedRowKeys => {
      setSelectedData(selectedRowKeys)
    }
  }

  const columns = [
    {
      title: 'Despesa',
      dataIndex: 'despesa'
    },
    {
      title: 'Valor',
      dataIndex: 'valor',
      render: text => formatarMoeda(text)
    },
    {
      title: 'Data',
      dataIndex: 'data',
      defaultSortOrder: 'descend',
      sorter: (a, b) => new Date(a.data) - new Date(b.data),
      render: text => moment(text).format('DD/MM/YYYY HH:mm')
    },
    {
      title: 'Anexo',
      dataIndex: 'anexo'
    },
    {
      title: 'Ações',
      dataIndex: 'acoes',
      key: 'x',
      render: (_, record) => (
        <Space size="middle">
          <a
            onClick={() => {
              setViewDetail(true)
              setDetails(record)
            }}
          >
            detalhes
          </a>
          <a
            style={{ color: 'red' }}
            onClick={() => {
              deleteData(record.id)
            }}
          >
            excluir
          </a>
        </Space>
      )
    }
  ]

  return (
    <div>
      {/* {console.log(upload)} */}

      <Helmet>
        <title>Prestação de contas – SETASC</title>
      </Helmet>

      {/* Modal de detalhes */}
      <Modal
        visible={viewDetail}
        destroyOnClose
        title="Detalhes da despesa"
        footer={[]}
        onCancel={() => {
          setViewDetail(false)
        }}
      >
        {details && (
          <>
            <Descriptions>
              <Descriptions.Item label="Criado em" span={1}>
                <p style={{ color: '#00000050' }}>
                  {moment(details.created_at).format('DD/MM/YYYY HH:mm')}
                </p>
              </Descriptions.Item>
              <Descriptions.Item label="Criado por" span={1}>
                <p style={{ color: '#00000050' }}>{details.criadoPor}</p>
              </Descriptions.Item>
            </Descriptions>
            <MenuActions>
              <ActionItem
                onClick={() => {
                  setViewDetail(false)
                  setNovaDespesa(true)
                  setEditMode(true)
                  delete details.data
                  form.setFieldsValue({
                    ...details,
                    data: moment(details.data)
                  })
                }}
              >
                <EditOutlined />
                <p>Editar</p>
              </ActionItem>
              <ActionItem
                style={{ color: 'red' }}
                onClick={() => {
                  deleteData(details.id)
                }}
              >
                <DeleteOutlined />
                <p>Excluir</p>
              </ActionItem>
            </MenuActions>
            <Divider />
            <Title level={4} style={{ paddingBottom: 10 }}>
              {details.despesa}
            </Title>
            <Descriptions layout="vertical">
              <Descriptions.Item label="Valor">
                <Text strong>{formatarMoeda(details.valor)}</Text>
              </Descriptions.Item>
              <Descriptions.Item label="Categoria">
                <Text strong>{details.categoria.categoria}</Text>
              </Descriptions.Item>
              <Descriptions.Item label="Data e hora" span={2}>
                <p style={{ color: '#00000050' }}>
                  {moment(details.data).format('LL')}
                </p>
              </Descriptions.Item>
              {details.descricao && (
                <Descriptions.Item label="Descrição" span={3}>
                  <Text>{details.descricao}</Text>
                </Descriptions.Item>
              )}
              {details.anexos &&
                details.anexos.map(({ anexo }) => {
                  if (anexo)
                    return (
                      <Descriptions.Item key={anexo} label="Anexo">
                        <Text
                          style={{ color: '#1890FF', cursor: 'pointer' }}
                          onClick={() => {
                            setViewAttach(true)
                            setModalAttach(anexo)
                          }}
                        >
                          <PaperClipOutlined /> Arquivo anexado
                        </Text>
                      </Descriptions.Item>
                    )
                  return <></>
                })}
            </Descriptions>

            <Modal
              visible={viewAttach}
              destroyOnClose
              onCancel={() => {
                setViewAttach(false)
              }}
              footer={[]}
            >
              <img src={modalAttach} alt="Arquivo anexado" />
            </Modal>
          </>
        )}
      </Modal>

      {/* Modal Nova Depensa */}
      <Modal
        visible={novaDespesa}
        destroyOnClose
        title="Nova despesa"
        footer={[
          <Button
            key="back"
            onClick={() => {
              setNovaDespesa(false)
            }}
          >
            Cancelar
          </Button>,
          <Button key="submit" type="primary" onClick={storeData}>
            Confirmar
          </Button>
        ]}
        onCancel={() => {
          setNovaDespesa(false)
        }}
      >
        <Form
          layout="vertical"
          form={form}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Form.Item
            label="Nome da despesa"
            name="despesa"
            rules={[
              {
                required: true,
                message: 'Informe o nome da despesa'
              }
            ]}
          >
            <Input placeholder="Digite titulo da despesa" size="large" />
          </Form.Item>

          <Form.Item label="Descrição" name="descricao">
            <TextArea
              rows={3}
              placeholder="Digite sobre a despesa"
              size="large"
              maxLength="140"
              showCount={true}
            />
          </Form.Item>

          <Row gutter={30} align="middle">
            <Col span={12}>
              <Form.Item
                label="Categoria"
                name="categoriaId"
                rules={[
                  {
                    required: true,
                    message: 'Informe a categoria da despesa'
                  }
                ]}
              >
                <Select
                  size="large"
                  optionLabelProp="label"
                  placeholder="Selecione ..."
                >
                  {categorias.map(item => (
                    <Select.Option
                      key={item.id}
                      value={item.id}
                      label={item.categoria}
                    >
                      {item.categoria}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12} style={{ paddingTop: 5 }}>
              <Button
                size="large"
                style={{ width: '100%' }}
                onClick={() => {
                  setNewCat(true)
                  formCat.resetFields()
                }}
              >
                Adicionar categoria
              </Button>
            </Col>
          </Row>

          <Row gutter={30}>
            <Col span={12}>
              <Form.Item
                label="Valor"
                name="valor"
                rules={[
                  {
                    required: true,
                    message: 'Informe o valor'
                  }
                ]}
              >
                <InputNumber
                  min={0}
                  formatter={value =>
                    `R$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  parser={value => value.replace(/R\$\s?|(,*)/g, '')}
                  defaultValue={0}
                  step={10}
                  precision={0.01}
                  size="large"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Data"
                name="data"
                rules={[
                  {
                    required: true,
                    message: 'Informe a data'
                  }
                ]}
              >
                <DatePicker
                  placeholder="Selecione a data"
                  size="large"
                  style={{ width: '100%' }}
                  locale={locale}
                  format="DD/MM/YYYY HH:mm"
                  showTime={{ defaultValue: moment('08:00', 'HH:mm') }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label="Incluir anexo" name="anexos">
            <Dragger {...upload}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Clique ou arraste o(s) arquivo(s)
              </p>
              <p className="ant-upload-hint">Upload de um ou mais arquivo(s)</p>
            </Dragger>
          </Form.Item>
        </Form>
      </Modal>

      {/* Modal de Nova Cat */}
      <Modal
        visible={newCat}
        destroyOnClose
        title="Adicionar Categoria"
        style={{ zIndex: 999 }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setNewCat(false)
              formCat.resetFields()
            }}
          >
            Cancelar
          </Button>,
          <Button key="submit" type="primary" onClick={storeCat}>
            Confirmar
          </Button>
        ]}
        onCancel={() => {
          setNewCat(false)
          formCat.resetFields()
        }}
      >
        <Form
          layout="vertical"
          form={formCat}
          initialValues={{
            status: true
          }}
        >
          <Form.Item
            name="categoria"
            required
            rules={[
              { required: true, message: 'A categoria precisa ser informada' }
            ]}
          >
            <Input placeholder="Nome da categoria" />
          </Form.Item>
          <Form.Item name="status" style={{ display: 'none' }}></Form.Item>
        </Form>
      </Modal>

      <main>
        <PageHeader
          title="Prestação de contas"
          subTitle={`${despesas.length} registros no total`}
          className="header-pages"
          extra={[
            <Input.Search
              key="search"
              allowClear
              style={{ width: 'fit-content' }}
              placeholder="Pesquisar"
              value={value}
              onChange={e => {
                const currValue = e.target.value
                setValue(currValue)
                const filteredData = despesas.filter(entry =>
                  entry.despesa.includes(currValue)
                )
                setFilterDespesas(filteredData)
              }}
            />,
            <Dropdown
              key="menu"
              overlay={
                <Menu>
                  <Menu.Item key="2">
                    <a
                      onClick={() => {
                        setNewCat(true)
                      }}
                    >
                      Adicionar Categoria
                    </a>
                  </Menu.Item>
                </Menu>
              }
            >
              <Button icon={<EllipsisOutlined />}></Button>
            </Dropdown>,
            <Button
              key="1"
              type="primary"
              icon={<PlusCircleOutlined />}
              size="medium"
              onClick={() => {
                setNovaDespesa(true)
                form.resetFields()
                setAttach('')
                setEditMode(false)
              }}
            >
              Nova despesa
            </Button>
          ]}
        />
        <ConfigProvider renderEmpty={TableEmpty}>
          <Table
            rowSelection={{
              type: selectionType,
              ...rowSelection
            }}
            columns={columns}
            loading={loading}
            dataSource={filterDespesas}
            pagination={{ PageSize: 10 }}
          />
        </ConfigProvider>
      </main>
    </div>
  )
}

export default Prestacao
