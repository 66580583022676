import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { useAuth } from 'contexts/auth'
import App from 'routes/app.routes'
import Login from 'pages/Login/Login'
import { AlterarSenha } from 'pages'

export default function Routes() {
  const { signed } = useAuth()

  if (!signed) {
    return (
      <Switch>
        <Route path="/" exact component={Login} />
        <Route path="/alterar/:token/:id" exact component={AlterarSenha} />
        <Route path="*" component={() => <Redirect to={{ pathname: '/' }} />} />
      </Switch>
    )
  } else {
    return (
      <Switch>
        <App />
      </Switch>
    )
  }
}
