import { Col, Row } from 'antd'
import styled from 'styled-components'

export const Container = styled(Row)`
  min-height: 100vh;
  width: 100%;
  background-image: linear-gradient(150deg, #183181, #d9d9d9);
`

export const FormCard = styled(Col)`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 1px 10px 10px -5px rgba(0, 0, 0, 0.5);
`

export const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
`

export const FormContainer = styled.div`
  align-items: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
`
export const BgImage = styled.img`
  height: auto;
  width: 100%;
`
