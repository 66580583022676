import axios from 'axios'

const api = axios.create({ baseURL: process.env.REACT_APP_API, timeout: 5000 })

api.interceptors.request.use(config => {
  config.headers.Authorization = `Bearer ${localStorage.getItem(
    'TOKEN_SETASC_API'
  )}`
  config.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'TOKEN_SETASC_API'
  )}`
  return config
})

export default api
