import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import AuthContext from '../contexts/auth'
import { MaskPhone } from '../services/utils'

import {
  Avatar,
  Badge,
  Button,
  Typography,
  Menu,
  Dropdown,
  Drawer,
  Form,
  Input,
  message,
  Upload
} from 'antd'
import {
  UserOutlined,
  BellOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  LogoutOutlined,
  UploadOutlined,
  InfoCircleOutlined
} from '@ant-design/icons'
import { MenuHead } from '../styles/global'
import api from 'services/api'

const { Text } = Typography

const tailLayout = {
  wrapperCol: { offset: 6, span: 16 }
}

function Header() {
  const { logout, user, setUser } = useContext(AuthContext)
  const history = useHistory()
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [drawerPasswordVisible, setDrawerPasswordVisible] = useState(false)
  const [attach, setAttach] = useState()
  const [attachAssinatura, setAttachAssinatura] = useState()

  const [userForm] = Form.useForm()
  const [passwordForm] = Form.useForm()
  const [buttonVerify, setButtonVerify] = useState(true)

  const upload = {
    name: 'file',
    multiple: true,
    action: `${process.env.REACT_APP_API}/upload`,
    method: 'POST',
    data: { pasta: 'avatares' },
    onChange(info) {
      const { status } = info.file
      if (status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (status === 'done') {
        message.success(`${info.file.name} sucesso ao fazer upload.`)
        setAttach(info.fileList.map(item => item.response.url))
      } else if (status === 'error') {
        message.error(`${info.file.name} falha ao fazer upload.`)
      }
    }
  }

  const uploadAssinatura = {
    name: 'file',
    multiple: true,
    action: `${process.env.REACT_APP_API}/upload`,
    method: 'POST',
    data: { pasta: 'avatares' },
    onChange(info) {
      const { status } = info.file
      if (status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (status === 'done') {
        message.success(`${info.file.name} sucesso ao fazer upload.`)
        setAttachAssinatura(info.fileList.map(item => item.response.url))
      } else if (status === 'error') {
        message.error(`${info.file.name} falha ao fazer upload.`)
      }
    }
  }

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Button
          icon={<SettingOutlined />}
          onClick={() => {
            setDrawerVisible(true)
            userForm.setFieldsValue(user)
          }}
        >
          Configurações
        </Button>
      </Menu.Item>
      <Menu.Item key="2">
        <Button
          icon={<LogoutOutlined />}
          onClick={async () => {
            await logout()
          }}
          style={{ width: '100%' }}
        >
          Sair
        </Button>
      </Menu.Item>
    </Menu>
  )

  const onClose = () => {
    setDrawerVisible(false)
    userForm.resetFields()
  }

  const onClosePassword = () => {
    setDrawerPasswordVisible(false)
    passwordForm.resetFields()
  }

  const onSave = () => {
    const data = userForm.getFieldsValue()
    if (attach) {
      data.foto = attach[0]
    }
    if (attachAssinatura) {
      data.assinatura = attachAssinatura[0]
    }
    api
      .put(`usuario/${user.id}`, data)
      .then(res => {
        localStorage.setItem('STORAGE_USER', JSON.stringify(res.data))
        message.success('Dados atualizados com sucesso!')
        setUser(res.data)
        onClose()
      })
      .catch(error => {
        message.error('Erro ao salvar os dados, tente novamente.')
        console.log(error)
      })
  }

  const onSavePassword = () => {
    const data = passwordForm.getFieldValue()
    api
      .put(`usuario/${user.id}`, data)
      .then(res => {
        message.success('Dados atualizados com sucesso!')
        setUser(res.data)
        onClosePassword()
      })
      .catch(error => {
        message.error('Erro ao salvar os dados, tente novamente.')
        console.log(error)
      })
  }

  return (
    <MenuHead>
      <Button
        key="a"
        type="primary"
        shape="circle"
        icon={<QuestionCircleOutlined />}
        size="medium"
        style={{ marginRight: '15px' }}
      />
      {/* <Badge count={5} key="b">
        <Button
          type="primary"
          shape="circle"
          icon={<BellOutlined />}
          size="medium"
        />
      </Badge> */}

      <Dropdown key="d" overlay={menu} placement="bottomRight" arrow>
        <div>
          <Avatar
            key="c"
            size={30}
            icon={<UserOutlined />}
            style={{ margin: '0 15px' }}
            src={user.foto}
          />
          <Text style={{ cursor: 'pointer', color: 'white' }}>
            {user.nome ? user.nome : 'User Name'}
          </Text>
        </div>
      </Dropdown>
      <Drawer
        title="Configurações da conta"
        width={500}
        onClose={onClose}
        visible={drawerVisible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} form={userForm}>
          <Form.Item {...tailLayout}>
            <Avatar size={128} icon={<UserOutlined />} src={user.foto} />
          </Form.Item>
          <Form.Item
            label="Nome"
            name="nome"
            rules={[
              {
                required: true,
                message: 'Por favor, o nome deve ser informado'
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Email" name="email">
            <Input disabled />
          </Form.Item>
          <Form.Item label="Senha">
            <Button
              onClick={() => {
                setDrawerPasswordVisible(true)
              }}
            >
              Alterar senha
            </Button>
          </Form.Item>
          <Form.Item
            label="Telefone"
            name="telefone"
            normalize={value => MaskPhone(value)}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Foto de perfil" name="foto">
            <Upload {...upload} listType="picture" maxCount={1}>
              <Button icon={<UploadOutlined />} style={{ width: 200 }}>
                Adicionar foto
              </Button>
            </Upload>
          </Form.Item>
          <Form.Item
            label="Assinatura"
            name="assinatura"
            tooltip={{
              title:
                'Assinatura é utilizada ao gerar pdf de um arquivo oficial',
              icon: <InfoCircleOutlined />
            }}
          >
            <Upload {...uploadAssinatura} listType="picture" maxCount={1}>
              <Button icon={<UploadOutlined />} style={{ width: 200 }}>
                Adicionar assinatura
              </Button>
            </Upload>
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button
              type="primary"
              htmlType="submit"
              onClick={onSave}
              style={{ marginRight: 20 }}
            >
              Salvar
            </Button>
            <Button danger onClick={onClose}>
              Cancelar
            </Button>
          </Form.Item>
        </Form>
        <Drawer
          title="Alterar Senha"
          width={420}
          closable={false}
          onClose={() => {
            setDrawerPasswordVisible(false)
          }}
          visible={drawerPasswordVisible}
        >
          <Form labelCol={{ span: 9 }} form={passwordForm}>
            <Form.Item
              name="password"
              label="Senha"
              rules={[
                {
                  required: true,
                  message: 'Por favor insira sua senha!'
                },
                {
                  min: 8,
                  message: 'A senha deve ter no mínimo 8 caracteres.'
                }
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="confirm"
              label="Confirmar senha"
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Por favor confirme sua senha!'
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      setButtonVerify(false)
                      return Promise.resolve()
                    }
                    setButtonVerify(true)
                    return Promise.reject(
                      new Error('As duas senhas devem ser iguais!')
                    )
                  }
                })
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 9, span: 16 }}>
              <Button
                type="primary"
                htmlType="submit"
                onClick={onSavePassword}
                disabled={buttonVerify}
                style={{ marginRight: 20 }}
              >
                Salvar
              </Button>
              <Button
                danger
                onClick={() => {
                  passwordForm.resetFields()
                  setDrawerPasswordVisible(false)
                }}
              >
                Cancelar
              </Button>
            </Form.Item>
          </Form>
        </Drawer>
      </Drawer>
    </MenuHead>
  )
}

export default Header
