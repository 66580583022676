import React from 'react'
import 'antd/dist/antd.css'

import './styles/global.css'

import { AuthProvider } from './contexts/auth'
import Routes from './routes'
import { BrowserRouter } from 'react-router-dom'

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </AuthProvider>
  )
}

export default App
