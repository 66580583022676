import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Menu } from 'antd'
import {
  ProfileOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  WalletOutlined,
  HistoryOutlined,
  SettingOutlined,
  BarChartOutlined,
  CommentOutlined,
  FileDoneOutlined,
  ReadOutlined
} from '@ant-design/icons'
import { MenuSide, Logo } from '../styles/global'
import { useAuth } from 'contexts/auth'

function MenuNav({ type }) {
  const { user } = useAuth()
  console.log(user)
  return (
    <MenuSide>
      <Logo>
        {type ? (
          <img src="../icone.png" height="50" alt="SETASC" />
        ) : (
          <img src="../logo.png" height="50" alt="SETASC" />
        )}
      </Logo>
      <Menu defaultSelectedKeys={['1']} mode="inline">
        <Menu.Item key="1" icon={<ProfileOutlined />}>
          <Link to="/atas">Ata de reunião</Link>
        </Menu.Item>
        <Menu.Item key="2" icon={<ExclamationCircleOutlined />}>
          <Link to="/denuncias">Denúncias</Link>
        </Menu.Item>
        <Menu.Item key="3" icon={<CheckCircleOutlined />}>
          <Link to="/providencias">Providências</Link>
        </Menu.Item>
        <Menu.Item key="4" icon={<CommentOutlined />}>
          <Link to="/notas">Notas recomendatórias</Link>
        </Menu.Item>
        <Menu.Item key="5" icon={<WalletOutlined />}>
          <Link to="/prestacao">Prestação de contas</Link>
        </Menu.Item>
        <Menu.Item key="6" icon={<FileDoneOutlined />}>
          <Link to="/demandas">Demandas</Link>
        </Menu.Item>
        <Menu.Item key="7" icon={<ReadOutlined />}>
          <Link to="/resolucoes">Resoluções</Link>
        </Menu.Item>
        <Menu.Item key="8" icon={<BarChartOutlined />}>
          <Link to="/relatorios">Relatórios</Link>
        </Menu.Item>
        <Menu.Item key="9" icon={<HistoryOutlined />}>
          <Link to="/historico">Histórico</Link>
        </Menu.Item>
        {user.perfil?.conselhos?.visualizar === true && (
          <Menu.Item key="10" icon={<SettingOutlined />}>
            <Link to="/administracao">Administração</Link>
          </Menu.Item>
        )}
        {user.perfil?.perfis?.visualizar === true && (
          <Menu.Item key="11" icon={<SettingOutlined />}>
            <Link to="/perfis">Perfis</Link>
          </Menu.Item>
        )}
      </Menu>
    </MenuSide>
  )
}

export default withRouter(MenuNav)
