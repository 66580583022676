/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import {
  Button,
  Table,
  Drawer,
  message,
  Spin,
  Space,
  Tooltip,
  Popconfirm,
  Input,
  Form,
  Skeleton,
  Checkbox,
  Divider,
  PageHeader,
  Tag,
  Select
} from 'antd'
import api from 'services/api'
import { useAuth } from 'contexts/auth'

const { Column } = Table

const plainOptions = ['CRIAR', 'VISUALIZAR', 'ATUALIZAR', 'APAGAR']
const defaultCheckedList = ['VISUALIZAR']

function Perfis() {
  const { user } = useAuth()

  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [mode, setMode] = useState('insert')
  const [load, setLoad] = useState(false)
  const [perfis, setPerfis] = useState([])
  const [perfil, setPerfil] = useState(null)
  const [conselhos, setConselhos] = useState([])

  const CheckboxGroup = Checkbox.Group

  useEffect(async () => {
    async function loadPerfis() {
      setLoading(true)
      try {
        await api.get('/perfil').then(({ data }) => {
          setPerfis(data)
        })
        setLoading(false)
      } catch (error) {
        if (error.response.data) {
          if (error.response.data.errors) {
            error.response.data.errors.forEach(e => message.error(e.message))
          } else {
            message.error(error.response.data.error)
          }
        } else {
          message.error('Erro ao excluir, tente novamente')
        }
      } finally {
        setLoading(false)
      }
    }
    loadPerfis()
  }, [load])

  const openDrawer = async () => {
    setVisible(true)
    try {
      await api.get(`/conselho`).then(({ data }) => {
        setConselhos(data)
      })
    } catch (error) {
      if (error?.response?.data) {
        if (error?.response?.data?.errors) {
          error.response.data.errors.forEach(e => message.error(e.message))
        } else {
          message.error(error.response.data.error)
        }
      } else {
        message.error('Erro ao excluir, tente novamente')
      }
    }
  }

  const onClose = () => {
    form.resetFields()
    setMode('insert')
    setVisible(false)
    setLoad(!load)
  }

  const objectToArray = record => {
    const perfis = { ...record }
    delete perfis.perfil
    delete perfis.created_at
    delete perfis.updated_at
    delete perfis.id
    const newPerfis = {}
    for (const perfil in perfis) {
      newPerfis[perfil] = []
      if (perfis[perfil].criar) {
        newPerfis[perfil].push('CRIAR')
      }
      if (perfis[perfil].visualizar) {
        newPerfis[perfil].push('VISUALIZAR')
      }
      if (perfis[perfil].apagar) {
        newPerfis[perfil].push('APAGAR')
      }
      if (perfis[perfil].atualizar) {
        newPerfis[perfil].push('ATUALIZAR')
      }
    }
    return newPerfis
  }

  const arrayToObject = async value => {
    const data = { ...value }
    delete data.perfil
    delete data.conselhoId
    for (const i in data) {
      if (value[i]) {
        const tempItem = value[i]
        data[i] = {
          criar: false,
          visualizar: false,
          atualizar: false,
          apagar: false
        }
        for (const item of tempItem) {
          if (item === 'CRIAR') {
            data[i].criar = true
          }
          if (item === 'VISUALIZAR') {
            data[i].visualizar = true
          }
          if (item === 'ATUALIZAR') {
            data[i].atualizar = true
          }
          if (item === 'APAGAR') {
            data[i].apagar = true
          }
        }
      } else {
        data[i] = {
          criar: false,
          visualizar: false,
          atualizar: false,
          apagar: false
        }
      }
    }
    return data
  }

  const handleSubmit = async value => {
    setLoading(true)
    const data = await arrayToObject(value)
    data.perfil = value.perfil
    data.conselhoId = value.conselhoId
    console.log(data)
    if (mode === 'insert') {
      api
        .post('/perfil', data)
        .then(() => {
          setLoading(false)
          message.success('Cadastro realizado com sucesso!')
          onClose()
        })
        .catch(e => {
          setLoading(false)
          if (e?.response?.data) {
            message.error(e.response.data)
            return
          }
          if (e.response.data.errors) {
            e.response.data.errors.forEach(info => {
              message.error(info.message)
            })
          } else {
            message.error(e.response.data.error)
          }
        })
    } else {
      api
        .put(`/perfil/${perfil.id}`, data)
        .then(() => {
          setLoading(false)
          message.success('Cadastro atualizado com sucesso!')
          onClose()
        })
        .catch(e => {
          setLoading(false)
          if (e.response.data.errors) {
            e.response.data.errors.forEach(info => {
              message.error(info.message)
            })
          } else {
            message.error(e.response.data.error)
          }
        })
    }
  }

  return (
    <main>
      <Skeleton loading={loading} active>
        <PageHeader
          className="header-pages"
          title="Perfis de acesso"
          subTitle={`${perfis.length} registros no total`}
          extra={[
            <Button
              icon={<PlusOutlined />}
              onClick={() => {
                setMode('insert')
                openDrawer()
              }}
              key="1"
            >
              Adicionar Perfil
            </Button>
          ]}
        />
        <Table
          dataSource={perfis}
          loading={loading}
          rowKey={record => record.id}
          size="middle"
        >
          <Column title="Perfil" dataIndex="perfil" key="perfil" />
          <Column
            title="Conselho"
            dataIndex="conselho"
            key="conselho"
            render={(_, record) => <Tag>{record.conselho.conselho}</Tag>}
          />
          <Column
            dataIndex="actions"
            key="actions"
            render={(_, record) => (
              <Space>
                <Tooltip title="Editar" placement="top">
                  <Button
                    icon={<EditOutlined />}
                    onClick={() => {
                      console.log(record)
                      setPerfil(record)
                      const itens = objectToArray(record)
                      itens.perfil = record.perfil
                      itens.id = record.id
                      itens.conselhoId = record.conselhoId
                      form.setFieldsValue(itens)
                      setMode('update')
                      openDrawer()
                    }}
                  />
                </Tooltip>
                <Tooltip title="Excluir Marca" placement="top">
                  <Popconfirm
                    title="Deseja realmente excluir este item?"
                    okText="Sim"
                    cancelText="Não"
                    onConfirm={async () => {
                      setLoad(true)
                      await api
                        .delete(`/perfil/${record.id}`)
                        .then(() => {
                          message.success('Item excluido com sucesso')
                          setLoad(false)
                        })
                        .catch(() => {
                          message.error('Erro ao excluir item, tente novamente')
                        })
                    }}
                  >
                    <Button
                      icon={<DeleteOutlined />}
                      style={{ color: '#e80909' }}
                    />
                  </Popconfirm>
                </Tooltip>
              </Space>
            )}
          />
        </Table>
        <Drawer
          visible={visible}
          onClose={onClose}
          width={850}
          title="Cadastrar Perfil"
        >
          <Spin spinning={loading}>
            <Form
              form={form}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 14 }}
              width={860}
              onFinish={handleSubmit}
            >
              <>
                <Form.Item
                  label="Perfil"
                  name="perfil"
                  wrapperCol={{ span: 12 }}
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, o nome do perfil deve ser informado'
                    }
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Conselho"
                  name="conselhoId"
                  wrapperCol={{ span: 12 }}
                >
                  <Select
                    placeholder="Selecione..."
                    optionFilterProp="children"
                    showSearch
                    disabled={
                      !user.perfil.conselhos.criar ||
                      !user.perfil.conselhos.atualizar
                    }
                  >
                    {conselhos.map(council => {
                      return (
                        <Select.Option key={council.id} value={council.id}>
                          {council.conselho}
                        </Select.Option>
                      )
                    })}
                  </Select>
                </Form.Item>
                <Divider />
                <Form.Item label="Conselhos" name="conselhos">
                  <CheckboxGroup
                    options={plainOptions}
                    defaultChecked={defaultCheckedList}
                  />
                </Form.Item>
                <Divider />
                <Form.Item label="Perfis" name="perfis">
                  <CheckboxGroup options={plainOptions} />
                </Form.Item>
                <Divider />
                <Form.Item label="Usuario" name="users">
                  <CheckboxGroup options={plainOptions} />
                </Form.Item>
                <Divider />
                <Form.Item label="Despesa" name="despesas">
                  <CheckboxGroup options={plainOptions} />
                </Form.Item>
                <Divider />
                <Form.Item label="Duvidas" name="duvidas">
                  <CheckboxGroup options={plainOptions} />
                </Form.Item>
                <Divider />
                <Form.Item label="Atas" name="atas">
                  <CheckboxGroup options={plainOptions} />
                </Form.Item>
                <Divider />
                <Form.Item label="Notas" name="notas">
                  <CheckboxGroup options={plainOptions} />
                </Form.Item>
                <Divider />
                <Form.Item label="Apresentacoes" name="apresentacoes">
                  <CheckboxGroup options={plainOptions} />
                </Form.Item>
                <Divider />
                <Form.Item label="Regimentos" name="regimentos">
                  <CheckboxGroup options={plainOptions} />
                </Form.Item>
                <Divider />
                <Form.Item label="Resolucoes" name="resolucoes">
                  <CheckboxGroup options={plainOptions} />
                </Form.Item>
                <Divider />
                <Form.Item label="Demandas" name="demandas">
                  <CheckboxGroup options={plainOptions} />
                </Form.Item>
                <Divider />
                <Form.Item label="Participantes" name="participantes">
                  <CheckboxGroup options={plainOptions} />
                </Form.Item>
                <Divider />
                <Form.Item label="Denuncias" name="denuncias">
                  <CheckboxGroup options={plainOptions} />
                </Form.Item>
                <Divider />
                <Form.Item wrapperCol={{ offset: 7 }}>
                  <Button
                    disabled={mode === 'view'}
                    type="primary"
                    htmlType="submit"
                  >
                    Cadastrar
                  </Button>
                  <Button danger onClick={onClose}>
                    Cancelar
                  </Button>
                </Form.Item>
              </>
            </Form>
          </Spin>
        </Drawer>
      </Skeleton>
    </main>
  )
}

export default Perfis
