import React, { useContext } from 'react'
import { Redirect, Route } from 'react-router-dom'
import AuthContext from 'contexts/auth'

// eslint-disable-next-line react/prop-types
function ProtectedRoute({ component, ...rest }) {
  const { signed } = useContext(AuthContext)

  return signed ? (
    <Route {...rest} component={component} />
  ) : (
    <Redirect to={{ pathname: '/' }} />
  )
}

export default ProtectedRoute
