/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback, useContext } from 'react'
import Helmet from 'react-helmet'

// Material UI
import {
  PageHeader,
  Button,
  Form,
  Input,
  Modal,
  Tabs,
  List,
  message,
  Typography,
  Collapse,
  Upload
} from 'antd'
import {
  PlusCircleOutlined,
  DeleteOutlined,
  InboxOutlined,
  ExclamationCircleOutlined,
  EditOutlined
} from '@ant-design/icons'

import api from 'services/api'
import { useAuth } from 'hooks/useAuth'

const { Text } = Typography
const { confirm } = Modal
const { TabPane } = Tabs
const { Panel } = Collapse
const { TextArea } = Input
const { Dragger } = Upload

function TabResol() {
  const [addResol, setAddResol] = useState(false)
  const [resolucoes, setResolucoes] = useState([])
  const [filtered, setFiltered] = useState([])
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [load, setLoad] = useState(false)
  const route = 'resolucao'
  const { user } = useAuth()

  // Listar atas
  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true)
        await refreshData()
      } catch {
        message.error('Erro ao carregar os dados!')
      } finally {
        setLoading(false)
      }
    }
    loadData()
  }, [load])

  const refreshData = useCallback(async () => {
    await api
      .get(`/${route}?conselhoId=${user.conselhoId}`)
      .then(({ data }) => {
        setFiltered(data)
        setResolucoes(data)
      })
  }, [])

  const onCancel = () => {
    form.resetFields()
    setAddResol(false)
  }

  const deleteData = id => {
    confirm({
      title: 'Você deseja realmente excluir?',
      icon: <ExclamationCircleOutlined />,
      content: 'Essa ação é irreversível após a confirmação',
      okText: 'Confirmar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk: async () => {
        await api
          .delete(`/${route}/${id}?conselhoId=${user.conselhoId}`)
          .then(() => {
            refreshData()
            message.success('Resolução excluída com sucesso')
          })
          .catch(() => {
            message.error('Erro ao excluir')
          })
      }
    })
  }

  const storeData = async () => {
    await form.validateFields().then(async values => {
      await api
        .post(`/${route}?conselhoId=${user.conselhoId}`, values)
        .then(() => {
          message.success('Resolução cadastrada com sucesso!')
          onCancel()
          refreshData()
        })
        .catch(e => {
          if (e.response.data) {
            if (e.response.data.errors) {
              e.response.data.errors.forEach(e => message.error(e.message))
            } else {
              message.error(e.response.data.error)
            }
          } else {
            message.error('Erro ao editar, tente novamente')
          }
        })
    })
  }

  const upload = {
    name: 'file',
    multiple: false,
    action: `${process.env.REACT_APP_API}/upload`,
    data: { pasta: 'resolucoes' },
    method: 'POST',
    onChange: info => {
      const { status } = info.file
      if (status === 'done') {
        message.success(`${info.file.name} sucesso ao fazer upload.`)
        form.setFieldsValue({ anexo: info.file.response.url })
      } else if (status === 'error') {
        message.error(`${info.file.name} falha ao fazer upload.`)
      }
    }
  }

  return (
    <>
      <Modal
        visible={addResol}
        width={500}
        title="Adicionar resolução"
        footer={[
          <Button key="back" onClick={onCancel}>
            Cancelar
          </Button>,
          <Button key="submit" type="primary" onClick={storeData}>
            Confirmar
          </Button>
        ]}
        onCancel={onCancel}
      >
        <Form
          name="add_resolucao"
          layout="vertical"
          form={form}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Form.Item
            label="Nome"
            name="titulo"
            rules={[
              {
                required: true,
                message: 'Informe o título do regime'
              }
            ]}
          >
            <Input placeholder="Nome/Título do regime" size="large" />
          </Form.Item>
          <Form.Item
            label="Descrição"
            name="descricao"
            rules={[
              {
                required: true,
                message: 'Informe uma descrição sobre o regime'
              }
            ]}
          >
            <TextArea
              size="large"
              placeholder="Breve descrição sobre documento"
              autoSize={{ minRows: 5, maxRows: 5 }}
            />
          </Form.Item>
          <Form.Item
            name="anexo"
            rules={[
              {
                required: true,
                message: 'Adicione o arquivo'
              }
            ]}
          >
            <Dragger {...upload}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Clique ou arraste o arquivo</p>
              <p className="ant-upload-hint">Upload de um único arquivo</p>
            </Dragger>
          </Form.Item>
        </Form>
      </Modal>
      <div style={{ textAlign: 'right', marginBottom: '30px' }}>
        {user.cargoId === 1 && (
          <Button
            key="1"
            type="primary"
            style={{ marginLeft: 30 }}
            icon={<PlusCircleOutlined />}
            size="medium"
            onClick={() => {
              setAddResol(true)
            }}
          >
            Adicionar novo documento
          </Button>
        )}
      </div>
      <List
        itemLayout="horizontal"
        dataSource={filtered}
        renderItem={item => (
          <List.Item
            actions={[
              <a
                key="download"
                href={item.anexo}
                target="_blank"
                rel="noreferrer"
              >
                baixar
              </a>,
              <a
                style={{ color: 'red' }}
                key="excluir"
                onClick={() => deleteData(item.id)}
              >
                excluir
              </a>
            ]}
          >
            <List.Item.Meta title={item.nome} description={item.descricao} />
          </List.Item>
        )}
      />
    </>
  )
}

function TabRegInt() {
  const [addRegimento, setAddRegimento] = useState(false)
  const [regimentos, setRegimentos] = useState([])
  const [filtered, setFiltered] = useState([])
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const route = 'regimento'
  const { user } = useAuth()

  // Listar atas
  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true)
        await refreshData()
      } catch {
        message.error('Erro ao carregar os dados!')
      } finally {
        setLoading(false)
      }
    }
    loadData()
  }, [])

  const refreshData = useCallback(async () => {
    await api
      .get(`/${route}?conselhoId=${user.conselhoId}`)
      .then(({ data }) => {
        setFiltered(data)
        setRegimentos(data)
      })
  }, [])

  const onCancel = () => {
    setAddRegimento(false)
    form.resetFields()
  }

  const deleteData = id => {
    confirm({
      title: 'Você deseja realmente excluir?',
      icon: <ExclamationCircleOutlined />,
      content: 'Essa ação é irreversível após a confirmação',
      okText: 'Confirmar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk: async () => {
        await api
          .delete(`/${route}/${id}?conselhoId=${user.conselhoId}`)
          .then(() => {
            refreshData()
            message.success('Regime excluída com sucesso')
          })
          .catch(() => {
            message.error('Erro ao excluir')
          })
      }
    })
  }

  const storeData = async () => {
    await form.validateFields().then(async values => {
      await api
        .post(`/${route}?conselhoId=${user.conselhoId}`, values)
        .then(() => {
          message.success('Regime cadastrada com sucesso!')
          refreshData()
          onCancel()
        })
        .catch(e => {
          if (e.response.data) {
            if (e.response.data.errors) {
              e.response.data.errors.forEach(e => message.error(e.message))
            } else {
              message.error(e.response.data.error)
            }
          } else {
            message.error('Erro ao editar, tente novamente')
          }
        })
    })
  }

  const upload = {
    name: 'file',
    multiple: false,
    action: `${process.env.REACT_APP_API}/upload`,
    method: 'POST',
    data: { pasta: 'regimentos' },
    onChange: info => {
      const { status } = info.file
      if (status === 'done') {
        message.success(`${info.file.name} sucesso ao fazer upload.`)
        form.setFieldsValue({ anexo: info.file.response.url })
      } else if (status === 'error') {
        message.error(`${info.file.name} falha ao fazer upload.`)
      }
    }
  }

  return (
    <>
      <Modal
        visible={addRegimento}
        width={500}
        title="Adicionar regime"
        footer={[
          <Button key="back" onClick={onCancel}>
            Cancelar
          </Button>,
          <Button key="submit" type="primary" onClick={storeData}>
            Confirmar
          </Button>
        ]}
        onCancel={onCancel}
      >
        <Form
          name="add_resolucao"
          layout="vertical"
          form={form}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Form.Item
            label="Nome"
            name="titulo"
            rules={[
              {
                required: true,
                message: 'Informe o título do regime'
              }
            ]}
          >
            <Input placeholder="Nome/Título do regime" size="large" />
          </Form.Item>
          <Form.Item
            label="Descrição"
            name="descricao"
            rules={[
              {
                required: true,
                message: 'Informe uma descrição sobre o regime'
              }
            ]}
          >
            <TextArea
              size="large"
              placeholder="Breve descrição sobre documento"
              autoSize={{ minRows: 5, maxRows: 5 }}
            />
          </Form.Item>
          <Form.Item
            name="anexo"
            rules={[
              {
                required: true,
                message: 'Adicione o arquivo'
              }
            ]}
          >
            <Dragger {...upload}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Clique ou arraste o arquivo</p>
              <p className="ant-upload-hint">Upload de um único arquivo</p>
            </Dragger>
          </Form.Item>
        </Form>
      </Modal>
      <div style={{ textAlign: 'right', marginBottom: '30px' }}>
        <Button
          key="1"
          type="primary"
          style={{ marginLeft: 30 }}
          icon={<PlusCircleOutlined />}
          size="medium"
          onClick={() => {
            setAddRegimento(true)
          }}
        >
          Adicionar novo documento
        </Button>
      </div>
      <List
        itemLayout="horizontal"
        dataSource={filtered}
        loading={loading}
        renderItem={item => (
          <List.Item
            actions={[
              <a
                href={item.anexo}
                target="_blank"
                rel="noreferrer"
                key="baixar"
              >
                baixar
              </a>,
              <a
                style={{ color: 'red' }}
                key="excluir"
                onClick={() => deleteData(item.id)}
              >
                excluir
              </a>
            ]}
          >
            <List.Item.Meta title={item.titulo} description={item.descricao} />
          </List.Item>
        )}
      />
    </>
  )
}

function TabPresent() {
  const [editar, setEditar] = useState(false)
  const [apresentacao, setApresentacao] = useState({
    titulo: '',
    descricao: ''
  })
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const route = 'apresentacao'
  const { user } = useAuth()

  // Listar atas
  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true)
        await refreshData()
      } catch (error) {
        if (error.response.data) {
          message.warning(error.response.data.error)
        } else {
          message.error('Erro ao carregar os dados!')
        }
      } finally {
        setLoading(false)
      }
    }
    loadData()
  }, [])

  const refreshData = useCallback(async () => {
    await api
      .get(`/${route}?conselhoId=${user.conselhoId}`)
      .then(({ data }) => {
        setApresentacao(data)
      })
  }, [])

  const onCancel = () => {
    form.resetFields()
    setEditar(false)
  }

  const updateData = async () => {
    await form.validateFields().then(async values => {
      await api
        .put(
          `/${route}/${apresentacao.id}?conselhoId=${user.conselhoId}`,
          values
        )
        .then(() => {
          onCancel()
          message.success('Sobre editado com sucesso!')
          refreshData()
        })
        .catch(e => {
          if (e.response.data) {
            if (e.response.data.errors) {
              e.response.data.errors.forEach(e => message.error(e.message))
            } else {
              message.error(e.response.data.error)
            }
          } else {
            message.error('Erro ao editar, tente novamente')
          }
        })
    })
  }

  const storeDataApresentacao = async () => {
    await form.validateFields().then(async values => {
      await api
        .post(`/${route}?conselhoId=${user.conselhoId}`, values)
        .then(() => {
          message.success('Nota cadastrada com sucesso!')
          refreshData()
          onCancel()
        })
        .catch(e => {
          if (e.response.data) {
            if (e.response.data?.errors) {
              e.response.data?.errors.forEach(e => message.error(e.message))
            } else {
              message.error(e.response.data?.error)
            }
          } else {
            message.error('Erro ao editar, tente novamente')
          }
        })
    })
  }

  return (
    <>
      <Modal
        visible={editar}
        width={500}
        title="Editar Sobre"
        footer={[
          <Button key="back" onClick={onCancel}>
            Cancelar
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              console.log(apresentacao)
              if (apresentacao.titulo !== '') {
                updateData()
              } else {
                storeDataApresentacao()
              }
            }}
          >
            Confirmar
          </Button>
        ]}
        onCancel={onCancel}
      >
        <Form
          name="edit_sobre"
          layout="vertical"
          form={form}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Form.Item label="Título" name="titulo">
            <Input placeholder="Nome do conselho" size="large" />
          </Form.Item>
          <Form.Item label="Sobre" name="descricao">
            <TextArea
              size="large"
              placeholder="Descreva sobre a secretaria/conselho"
              autoSize={{ minRows: 10, maxRows: 20 }}
            />
          </Form.Item>
        </Form>
      </Modal>

      <section style={{ backgroundColor: '#FFFFFF', padding: '17px 20px' }}>
        <PageHeader
          className="header-pages"
          title={apresentacao.titulo}
          style={{ padding: '0 0 20px 0', margin: 0 }}
          extra={[
            <Button
              key="1"
              type="secondary"
              icon={<EditOutlined />}
              size="medium"
              onClick={() => {
                form.setFieldsValue({ ...apresentacao })
                setEditar(true)
              }}
            >
              Editar
            </Button>
          ]}
        />
        <p style={{ paddingTop: 15 }}>{apresentacao.descricao}</p>
      </section>
    </>
  )
}

function TabFaq() {
  const [duvidas, setDuvidas] = useState([])
  const [filtered, setfiltered] = useState([])
  const [newFaq, setNewFaq] = useState(false)
  const [search, setSearch] = useState('')
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const route = 'duvida'
  const { user } = useAuth()

  // Listar atas
  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true)
        await refreshData()
      } catch {
        message.error('Erro ao carregar os dados!')
      } finally {
        setLoading(false)
      }
    }
    loadData()
  }, [])

  const refreshData = useCallback(async () => {
    await api
      .get(`/${route}?conselhoId=${user.conselhoId}`)
      .then(({ data }) => {
        setfiltered(data)
        setDuvidas(data)
      })
  }, [])

  const onCancel = () => {
    setNewFaq(false)
    form.resetFields()
  }

  const deleteData = id => {
    confirm({
      title: 'Você deseja realmente excluir?',
      icon: <ExclamationCircleOutlined />,
      content: 'Essa ação é irreversível após a confirmação',
      okText: 'Confirmar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk: async () => {
        await api
          .delete(`/${route}/${id}`)
          .then(() => {
            refreshData()
            message.success('FAQ excluída com sucesso')
          })
          .catch(() => {
            message.error('Erro ao excluir')
          })
      }
    })
  }

  const storeData = async () => {
    await form.validateFields().then(async values => {
      await api
        .post(`/${route}?conselhoId=${user.conselhoId}`, values)
        .then(() => {
          message.success('Nota cadastrada com sucesso!')
          refreshData()
          onCancel()
        })
        .catch(e => {
          if (e.response.data) {
            if (e.response.data?.errors) {
              e.response.data?.errors.forEach(e => message.error(e.message))
            } else {
              message.error(e.response.data?.error)
            }
          } else {
            message.error('Erro ao editar, tente novamente')
          }
        })
    })
  }

  return (
    <>
      <Modal
        visible={newFaq}
        width={500}
        title="Novo FAQ"
        footer={[
          <Button key="back" onClick={onCancel}>
            Cancelar
          </Button>,
          <Button key="submit" type="primary" onClick={storeData}>
            Confirmar
          </Button>
        ]}
        onCancel={onCancel}
      >
        <Form
          name="novo_faq"
          layout="vertical"
          form={form}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Form.Item label="Título" name="titulo">
            <Input placeholder="Título ou pergunta" size="large" />
          </Form.Item>
          <Form.Item label="Descrição" name="descricao">
            <TextArea
              placeholder="Descreva sobre a FAQ"
              size="large"
              autoSize={{ minRows: 10, maxRows: 20 }}
            />
          </Form.Item>
        </Form>
      </Modal>

      <section>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Input.Search
            allowClear
            style={{ width: '100%', marginBottom: 30 }}
            defaultValue=""
            placeholder="Pesquisar"
            value={search}
            onChange={e => {
              const currValue = e.target.value.toLowerCase()
              setSearch(currValue)
              const filteredData = duvidas.filter(entry =>
                entry.titulo.toLowerCase().includes(currValue)
              )
              setfiltered(filteredData)
            }}
          />
          {user.cargoId === 1 && (
            <Button
              key="1"
              type="primary"
              style={{ marginLeft: 30 }}
              icon={<PlusCircleOutlined />}
              size="medium"
              onClick={() => {
                setNewFaq(true)
              }}
            >
              Adicionar novo FAQ
            </Button>
          )}
        </div>
        <Collapse defaultActiveKey={['1']}>
          {duvidas.map(item => (
            <Panel
              header={item.titulo}
              key={item.id}
              extra={
                user.cargoId === 1 && (
                  <DeleteOutlined
                    color="red"
                    onClick={() => deleteData(item.id)}
                  />
                )
              }
            >
              <p>{item.descricao}</p>
            </Panel>
          ))}
        </Collapse>
      </section>
    </>
  )
}

function Resolucoes() {
  return (
    <div>
      <Helmet>
        <title>Resoluções – SETASC</title>
      </Helmet>

      <main>
        <PageHeader
          title="Resoluções - SETASC"
          subTitle="Regulamentos e regimentos de conselheiros"
          className="head-tab header-pages"
          footer={
            <Tabs defaultActiveKey="2">
              <TabPane tab="Apresentação" key="1">
                <TabPresent />
              </TabPane>
              <TabPane tab="Regimento Interno" key="2">
                <TabRegInt />
              </TabPane>
              <TabPane tab="Resoluções" key="3">
                <TabResol />
              </TabPane>
            </Tabs>
          }
        />
      </main>
    </div>
  )
}

export default Resolucoes
