import React, { useState } from 'react'
import { Form, Input, Button, Spin, message, Col, Row } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { Container, FormCard, BgImage, LogoContainer } from './style'
import logo from 'assets/logo.png'
import bg from 'assets/images/login.png'
import { useAuth } from 'hooks/useAuth'
import api from 'services/api'

function Login() {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [isRecover, setIsRecover] = useState(false)
  const { login } = useAuth()

  const onFinish = async values => {
    setLoading(true)
    await login(values)
      .then(() => {
        history.push('/atas')
        message.success('Sucesso ao fazer login')
      })
      .catch(err => {
        if (err.response.data) {
          message.error(err.response.data.message)
        } else {
          message.error('Erro ao fazer login, atualize a página!')
        }
        setLoading(false)
      })
  }

  const onRecover = async values => {
    setLoading(true)
    await api
      .post('/recuperar', values)
      .then(() => {
        message.success('Email de recuperação enviado!')
        setIsRecover(false)
      })
      .catch(err => {
        if (err.response.data) {
          if (err.response.data.message) {
            message.error(err.response.data.message)
          } else {
            err.response.data.errors.map(error => message.error(error.message))
          }
        } else {
          message.error('Erro ao fazer requisição, tente novamente')
        }
      })
      .finally(() => setLoading(false))
  }

  return (
    <Container align="middle" justify="center">
      <FormCard xxl={10} xl={16} lg={18} md={20}>
        <Row>
          <Col span={12}>
            <BgImage src={bg} />
          </Col>
          <Col span={12} style={{ padding: '1em' }}>
            <LogoContainer>
              <img src={logo} alt="logo" />
            </LogoContainer>
            <Spin spinning={loading}>
              {!isRecover ? (
                <Form onFinish={onFinish} name="login" autoComplete="off">
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'O email deve ser informado.'
                      },
                      {
                        type: 'email',
                        message: 'Por favor informe um email válido!'
                      }
                    ]}
                  >
                    <Input
                      placeholder="email"
                      size="large"
                      prefix={<UserOutlined style={{ color: '#183181' }} />}
                    />
                  </Form.Item>

                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'A senha deve ser informada!'
                      }
                    ]}
                  >
                    <Input.Password
                      placeholder="senha"
                      size="large"
                      prefix={<LockOutlined style={{ color: '#183181' }} />}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="link"
                      size="large"
                      onClick={() => setIsRecover(true)}
                    >
                      Esqueceu a senha?
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      style={{ background: '#183181', color: '#FFF' }}
                      block
                      size="large"
                      htmlType="submit"
                    >
                      Entrar
                    </Button>
                  </Form.Item>
                </Form>
              ) : (
                <Form onFinish={onRecover} name="recover" autoComplete="off">
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'O email deve ser informado.'
                      },
                      {
                        type: 'email',
                        message: 'Por favor informe um email válido!'
                      }
                    ]}
                  >
                    <Input
                      placeholder="Email cadastrado"
                      size="large"
                      prefix={<UserOutlined style={{ color: '#183181' }} />}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      style={{ background: '#183181', color: '#FFF' }}
                      block
                      size="large"
                      htmlType="submit"
                    >
                      Recuperar senha
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="link"
                      size="large"
                      onClick={() => setIsRecover(false)}
                    >
                      Voltar para Login
                    </Button>
                  </Form.Item>
                </Form>
              )}
            </Spin>
          </Col>
        </Row>
      </FormCard>
    </Container>
  )
}

export default Login
