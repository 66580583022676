import React, { useState, useEffect, useCallback, useContext } from 'react'
import Helmet from 'react-helmet'
import moment from 'moment'
import 'moment/locale/pt-br'
import AuthContext from 'contexts/auth'
// Material UI
import {
  Tag,
  Divider,
  PageHeader,
  Table,
  Space,
  Button,
  ConfigProvider,
  Input,
  Modal,
  Typography,
  message,
  Form,
  Upload,
  Timeline,
  Descriptions
} from 'antd'
import {
  PlusSquareOutlined,
  PaperClipOutlined,
  InboxOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons'

import { MenuActions, ActionItem } from 'styles/global'

// Componentes
import api from 'services/api'
import TableEmpty from '../components/TableEmpty'

const { Text, Title } = Typography
const { TextArea } = Input
const { Dragger } = Upload
const { confirm } = Modal

function Providencias() {
  const selectionType = 'checkbox'
  const route = 'denuncia'
  const [form] = Form.useForm()
  const [viewProvidence, setViewProvidence] = useState(false)
  const [newProvidence, setNewProvidence] = useState(false)
  const { user } = useContext(AuthContext)
  const [data, setData] = useState([])
  const [dataSource, setDataSource] = useState(data)
  const [loading, setLoading] = useState(false)
  const [load, setLoad] = useState(false)
  const [value, setValue] = useState('')
  const [selectedData, setSelectedData] = useState()
  const [details, setDetails] = useState()
  const [dataProvidence, setDataProvidence] = useState()
  const [attach, setAttach] = useState()
  const [finishReport, setFinishReport] = useState(false)

  const upload = {
    name: 'file',
    multiple: false,
    action: `${process.env.REACT_APP_API}/upload`,
    method: 'POST',
    data: { pasta: 'providencias' },
    onChange(info) {
      const { status } = info.file
      if (status !== 'uploading') {
        console.log(info.file)
      }
      if (status === 'done') {
        message.success(`${info.file.name} sucesso ao fazer upload.`)
        setAttach(info.file.response.url)
      } else if (status === 'error') {
        message.error(`${info.file.name} falha ao fazer upload.`)
      }
    },
    onDrop(e) {
      console.log('Arquivos upados', e.dataTransfer.files)
    }
  }
  // Listar atas
  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true)
        await refreshData()
        providenceData()
      } catch {
        message.error('Erro ao carregar os dados!')
      } finally {
        setLoading(false)
      }
    }
    loadData()
  }, [load])

  const refreshData = useCallback(async () => {
    await api
      .get(`/${route}?conselhoId=${user.conselhoId}`)
      .then(({ data }) => {
        setDataSource(data)
        setData(data)
      })
  }, [])

  const providenceData = useCallback(async () => {
    await api
      .get(`/providencia?conselhoId=${user.conselhoId}`)
      .then(({ data }) => {
        setDataProvidence(data)
      })
  }, [])

  const storeData = () => {
    form.setFieldsValue({
      ...form,
      anexo: attach
    })
    form
      .validateFields()
      .then(values => {
        api
          .post(`/providencia?conselhoId=${user.conselhoId}`, values)
          .then(() => {
            message.success('Providência registrada com sucesso!')
            refreshData()
            providenceData()
            setNewProvidence(false)
            form.resetFields()
            setAttach('')
            setLoad(!load)
          })
          .catch(e => {
            console.log(e)
            message.error('Erro ao cadastrar!')
          })
      })
      .catch(values => {
        console.log(values.errorFields)
      })
  }

  const modalFinish = id => {
    confirm({
      title: 'Você deseja realmente finalizar?',
      icon: <ExclamationCircleOutlined />,
      content: 'Essa ação é irreversível após a confirmação',
      okText: 'Confirmar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk: async () => {
        await api
          .put(`/${route}/${id}`, { status: 'finalizado' })
          .then(() => {
            refreshData()
            setFinishReport(false)
            message.success('Denúncia finalizada com sucesso!')
          })
          .catch(() => {
            message.error('Erro ao excluir essa denúncia')
          })
      }
    })
  }

  const rowSelection = {
    onChange: selectedRowKeys => {
      setSelectedData(selectedRowKeys)
    }
  }

  const columns = [
    {
      title: 'Protocolo',
      dataIndex: 'protocolo',
      sorter: (a, b) => a.protocolo - b.protocolo
    },
    {
      title: 'Status',
      dataIndex: ['prioridade', 'status'],
      render: (text, row) => (
        <>
          <Tag
            color={
              row.status === 'aprovado'
                ? `green`
                : row.status === 'finalizado'
                ? `volcano`
                : ``
            }
            style={{
              textTransform: 'capitalize'
            }}
          >
            {row.status}
          </Tag>
          <Tag
            color={
              row.prioridade === 'grave'
                ? `orange`
                : row.prioridade === 'prioritaria'
                ? `cyan`
                : row.prioridade === 'urgente'
                ? `volcano`
                : ``
            }
            style={{
              textTransform: 'capitalize'
            }}
          >
            {row.prioridade}
          </Tag>
        </>
      )
    },
    {
      title: 'Data',
      dataIndex: 'data',
      sorter: (a, b) => new Date(a.data) - new Date(b.data),
      render: text => <span>{moment(text).format('DD/MM/YYYY HH:mm')}</span>
    },
    {
      title: 'Criado por',
      dataIndex: 'user',
      render: text => text.nome
    },
    {
      title: 'Criado em',
      dataIndex: 'created_at',
      defaultSortOrder: 'descend',
      sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
      render: text => <span>{moment(text).format('DD/MM/YYYY HH:mm')}</span>
    },
    {
      title: 'Ações',
      dataIndex: 'acoes',
      key: 'acoes',
      render: (text, record) => (
        <Space size="middle">
          <a
            onClick={() => {
              setViewProvidence(true)
              setDetails(record)
            }}
          >
            detalhes
          </a>
        </Space>
      )
    }
  ]

  return (
    <div>
      <Helmet>
        <title>Providências – SETASC</title>
      </Helmet>

      <Modal
        visible={viewProvidence}
        title="Detalhes da denúncia"
        footer={[]}
        onCancel={() => {
          setViewProvidence(false)
          setDetails('')
        }}
        width={700}
      >
        {details && (
          <>
            <Descriptions>
              <Descriptions.Item
                label="Protocolo"
                span={2}
                style={{ paddingBottom: 0 }}
              >
                <p style={{ color: '#00000050' }}>{details.protocolo}</p>
              </Descriptions.Item>
              <Descriptions.Item label="" style={{ paddingBottom: 0 }}>
                <Tag
                  color={
                    details.prioridade === 'grave'
                      ? `orange`
                      : details.prioridade === 'prioritaria'
                      ? `cyan`
                      : details.prioridade === 'urgente'
                      ? `volcano`
                      : ``
                  }
                  style={{
                    textTransform: 'capitalize'
                  }}
                >
                  {details.prioridade}
                </Tag>
                {details.status === 'finalizado' && (
                  <Tag
                    color="volcano"
                    style={{
                      textTransform: 'capitalize'
                    }}
                  >
                    {details.status}
                  </Tag>
                )}
              </Descriptions.Item>
            </Descriptions>
            <Descriptions>
              <Descriptions.Item label="Criado em" span={2}>
                <p style={{ color: '#00000050' }}>
                  {moment(details.created_at).format('LLL')}
                </p>
              </Descriptions.Item>
              <Descriptions.Item label="Criado por">
                <p style={{ color: '#00000050' }}>{details.user.nome}</p>
              </Descriptions.Item>
              {details.status !== 'finalizado' && (
                <MenuActions>
                  <ActionItem
                    onClick={() => {
                      modalFinish(details.id)
                    }}
                  >
                    <CheckCircleOutlined />
                    <p>Finalizar Denúncia</p>
                  </ActionItem>
                </MenuActions>
              )}
            </Descriptions>
            <Divider />
            <Descriptions layout="vertical">
              <Descriptions.Item
                label="Data do ocorrido"
                style={{ paddingBottom: 7 }}
              >
                <Title level={4}>{moment(details.data).format('ll')}</Title>
              </Descriptions.Item>
              <Descriptions.Item label="Hora" style={{ paddingBottom: 7 }}>
                <Title level={4}>
                  {moment(details.data).format('HH[h] MM[min]')}
                </Title>
              </Descriptions.Item>
            </Descriptions>

            <div
              style={{ padding: '15px 0 45px' }}
              dangerouslySetInnerHTML={{
                __html: details.relato
              }}
            />
            <Divider />
            {details.complementos.length > 0 && (
              <>
                <Text strong style={{ color: '#143A7F' }}>
                  Informações complementares
                </Text>
                <Timeline style={{ padding: '30px 0 0px', width: '80%' }}>
                  {details.complementos.map(item => (
                    <Timeline.Item color="#143A7F" key={item.id}>
                      {item.informacoes}
                      <p style={{ color: '#00000050', paddingTop: 7 }}>
                        {moment(item.created_at).format('LLL')} por{' '}
                        {item.autor.nome}
                      </p>
                    </Timeline.Item>
                  ))}
                </Timeline>
                <Divider />
              </>
            )}

            <Text strong style={{ color: '#143A7F' }}>
              Providências
            </Text>
            <MenuActions style={{ paddingTop: 20 }}>
              <ActionItem
                onClick={() => {
                  setNewProvidence(true)
                  form.setFieldsValue({ ...form, denunciaId: details.id })
                }}
              >
                <PlusSquareOutlined />
                <p>Adicionar providências tomadas</p>
              </ActionItem>
            </MenuActions>
            <Timeline style={{ padding: '30px 0 25px', width: '80%' }}>
              {dataProvidence.map(
                item =>
                  item.denunciaId === details.id && (
                    <Timeline.Item color="#143A7F">
                      <p>{item.providencia}</p>
                      {item.anexo && (
                        <a href={item.anexo} target="_blank" rel="noreferrer">
                          <Text style={{ color: '#1890FF', cursor: 'pointer' }}>
                            <PaperClipOutlined /> Arquivo anexado
                          </Text>
                        </a>
                      )}

                      <p style={{ color: '#00000050', paddingTop: 7 }}>
                        {moment(item.created_at).format('LLL')}
                      </p>
                    </Timeline.Item>
                  )
              )}
            </Timeline>
          </>
        )}
      </Modal>

      <Modal
        visible={newProvidence}
        title="Adicionar providência"
        footer={[
          <Button
            key="back"
            onClick={() => {
              setNewProvidence(false)
              form.resetFields()
              setAttach('')
            }}
          >
            Cancelar
          </Button>,
          <Button key="submit" type="primary" onClick={storeData}>
            Adicionar
          </Button>
        ]}
        onCancel={() => {
          setNewProvidence(false)
          form.resetFields()
          setAttach('')
        }}
      >
        <Form
          name="providencia"
          layout="vertical"
          form={form}
          initialValues={{
            denunciaId: form.getFieldValue('denunciaId')
          }}
        >
          <Form.Item label="Providência" name="providencia">
            <TextArea
              size="large"
              placeholder="Descreva a providencia tomada"
              autoSize={{ minRows: 5, maxRows: 8 }}
            />
          </Form.Item>
          <Form.Item name="denunciaId" style={{ display: 'none' }}></Form.Item>
          <Form.Item label="Adicionar anexo" name="anexo">
            <Dragger {...upload}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Clique ou arraste o arquivo</p>
              <p className="ant-upload-hint">Upload de um ou mais arquivo</p>
            </Dragger>
          </Form.Item>
        </Form>
      </Modal>

      <main>
        <PageHeader
          title="Providências"
          subTitle={`${data.length} registros no total`}
          className="header-pages"
          extra={[
            <Input.Search
              key="1"
              allowClear
              style={{ width: 'fit-content' }}
              defaultValue=""
              placeholder="Pesquisar"
              value={value}
              onChange={e => {
                const currValue = e.target.value
                setValue(currValue)
                const filteredData = data.filter(entry =>
                  entry.protocolo.includes(currValue)
                )
                setDataSource(filteredData)
              }}
            />
            // <Dropdown
            //   key="2"
            //   overlay={
            //     <Menu>
            //       <Menu.Item key="2">
            //         <a>Excluir selecionados</a>
            //       </Menu.Item>
            //     </Menu>
            //   }
            // >
            //   <Button icon={<EllipsisOutlined />}></Button>
            // </Dropdown>
          ]}
        />
        <ConfigProvider renderEmpty={TableEmpty}>
          <Table
            rowSelection={{
              type: selectionType,
              ...rowSelection
            }}
            columns={columns}
            loading={loading}
            dataSource={dataSource.filter(
              aprovado =>
                aprovado.status === 'aprovado' ||
                aprovado.status === 'finalizado'
            )}
            pagination={{ PageSize: 10 }}
          />
        </ConfigProvider>
      </main>
    </div>
  )
}

export default Providencias
