import React, { useState, useEffect } from 'react'
import Helmet from 'react-helmet'
import api from 'services/api'
import { format, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'
// Material UI
import {
  Select,
  Divider,
  PageHeader,
  List,
  Button,
  ConfigProvider,
  Input,
  Modal,
  Typography,
  Form,
  message,
  Descriptions
} from 'antd'
import {
  PlusCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons'

import { MenuActions, ActionItem } from 'styles/global'

// Componentes
import TableEmpty from 'components/TableEmpty'
import { useAuth } from 'hooks/useAuth'

const { Text, Title } = Typography
const { TextArea } = Input

const data = [
  {
    key: '1',
    nome: 'Lorem ipsum dolor sit amet',
    descricao:
      'Lorem ipsum dolor sit amet, consectetur adipisici elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Magna pars studiorum, prodita quaerimus. Lorem ipsum dolor sit amet, consectetur adipisici elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.'
  },
  {
    key: '1',
    nome: 'Lorem ipsum dolor sit amet',
    descricao:
      'Lorem ipsum dolor sit amet, consectetur adipisici elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Magna pars studiorum, prodita quaerimus. Lorem ipsum dolor sit amet, consectetur adipisici elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.'
  }
]

function Demandas() {
  const { user } = useAuth()

  const [dataSource, setDataSource] = useState([])
  const [atas, setAtas] = useState([])
  const [search, setSearch] = useState('')
  const [newDemand, setNewDemand] = useState(false)
  const [viewDemand, setViewDemand] = useState(false)
  const [load, setLoad] = useState(false)
  const [demanda, setDemanda] = useState({})
  const [mode, setMode] = useState('insert')

  const [form] = Form.useForm()

  useEffect(() => {
    async function loadDemandas() {
      try {
        await api.get(`/demanda?conselhoId=${user.conselhoId}`).then(data => {
          setDataSource(data.data)
        })
      } catch (error) {
        console.log(error)
        console.error('Erro ao carregar os dados, atualize a pagina')
      }
    }
    loadDemandas()
  }, [load])

  const loadAtas = async () => {
    await api.get(`/ata?conselhoId=${user.conselhoId}`).then(({ data }) => {
      console.log(data.data)
      setAtas(data.data)
    })
  }

  const deleteData = (id, route) => {
    confirm({
      title: 'Você deseja realmente excluir?',
      icon: <ExclamationCircleOutlined />,
      content: 'Essa ação é irreversível após a confirmação',
      okText: 'Confirmar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk: async () => {
        await api
          .delete(`/${route}/${id}`)
          .then(() => {
            setLoad(!load)
          })
          .catch(() => {
            message.error('Erro ao excluir essa denúncia')
          })
      }
    })
  }

  const onClose = () => {
    setMode('')
    form.resetFields()
    setNewDemand(false)
  }

  const onSubmit = async () => {
    const data = form.getFieldValue()
    data.conselhoId = user.conselhoId
    if (mode === 'update') {
      try {
        await api
          .put(`/demanda/${demanda.id}`, data)
          .then(() => {
            message.success('Demanda atualizada com sucesso!')
            setLoad(!load)
            onClose()
          })
          .catch(e => {
            if (e.response.data.errors) {
              e.response.data.errors.forEach(info => {
                message.error(info.message)
              })
            } else {
              message.error(e.response.data.error)
            }
          })
      } catch (error) {
        message.error('Erro ao salvar os dados, tente novamente')
      }
    } else {
      try {
        await api
          .post('/demanda', data)
          .then(() => {
            message.success('Demanda cadastratdo com sucesso!')
            setLoad(!load)
            onClose()
          })
          .catch(e => {
            if (e.response.data.errors) {
              e.response.data.errors.forEach(info => {
                message.error(info.message)
              })
            } else {
              message.error(e.response.data.error)
            }
          })
      } catch (error) {
        message.error('Erro ao salvar os dados, tente novamente')
      }
    }
  }

  return (
    <div>
      <Helmet>
        <title>Demandas – SETASC</title>
      </Helmet>

      <Modal
        visible={viewDemand}
        destroyOnClose
        title="Detalhes da demanda"
        footer={[]}
        onCancel={() => {
          setViewDemand(false)
        }}
        width={600}
      >
        <Descriptions>
          <Descriptions.Item label="Criado em" span={2}>
            <p style={{ color: '#00000050' }}>
              {demanda.created_at
                ? format(
                    parseISO(demanda.created_at),
                    "dd 'de' MMMM' de ' yyyy'",
                    {
                      locale: ptBR
                    }
                  )
                : ''}
            </p>
          </Descriptions.Item>
          <Descriptions.Item label="Criado por" span={2}>
            <p style={{ color: '#00000050' }}>{demanda.autor?.nome}</p>
          </Descriptions.Item>
        </Descriptions>

        <MenuActions>
          <ActionItem
            onClick={() => {
              setMode('update')
              loadAtas()
              setViewDemand(false)
              setNewDemand(true)
              form.setFieldsValue(demanda)
            }}
          >
            <EditOutlined />
            <p>Editar</p>
          </ActionItem>
          <ActionItem
            style={{ color: 'red' }}
            onClick={() => {
              deleteData()
            }}
          >
            <DeleteOutlined />
            <p>Excluir</p>
          </ActionItem>
        </MenuActions>
        <Divider />
        <Title level={4}>{demanda.titulo}</Title>
        {demanda.atas?.length >= 0 &&
          demanda.atas.map(ata => {
            return (
              <Descriptions key={ata.id} layout="horizontal">
                <Descriptions.Item
                  label={<Text>Demanda discutida na reunião</Text>}
                  style={{ padding: '10px 0' }}
                  span={2}
                >
                  <p style={{ color: '#1890FF', cursor: 'pointer' }}>
                    {ata.numero}
                  </p>
                </Descriptions.Item>
              </Descriptions>
            )
          })}
        <div style={{ padding: '0px 0 30px' }}>
          <Text>{demanda.descricao}</Text>
        </div>
      </Modal>

      <Modal
        visible={newDemand}
        destroyOnClose
        width={500}
        title="Nova demanda"
        footer={[
          <Button
            key="back"
            onClick={() => {
              onClose()
            }}
          >
            Cancelar
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              onSubmit()
            }}
          >
            Confirmar
          </Button>
        ]}
        onCancel={() => {
          onClose()
        }}
      >
        <Form
          form={form}
          layout="vertical"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Form.Item label="Titulo" name="titulo">
            <Input placeholder="Nome" size="large" />
          </Form.Item>
          <Form.Item label="Descrição" name="descricao">
            <TextArea
              size="large"
              placeholder="Descreva sobre a demanda"
              autoSize={{ minRows: 10, maxRows: 20 }}
            />
          </Form.Item>
          <Form.Item
            label="Reunião"
            name="ataId"
            tooltip="Selecione a reunião caso essa demanda foi discutida"
          >
            <Select
              style={{ width: 200 }}
              size="large"
              showSearch
              placeholder="Selecione..."
            >
              {atas.map(ata => (
                <Select.Option key={ata.id} value={ata.id} label={ata.numero}>
                  {ata.numero}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>

      <main>
        <PageHeader
          className="header-pages"
          title="Demandas"
          subTitle={`${dataSource.length} registros no total`}
          extra={[
            <Input.Search
              key="2"
              allowClear
              style={{ width: 'fit-content' }}
              defaultValue=""
              placeholder="Pesquisar"
              value={search}
              onChange={e => {
                const currValue = e.target.value
                setSearch(currValue)
                const filteredData = data.filter(entry =>
                  entry.nome.includes(currValue)
                )
                setDataSource(filteredData)
              }}
            />,
            <Button
              key="1"
              type="primary"
              icon={<PlusCircleOutlined />}
              size="medium"
              onClick={() => {
                loadAtas()
                setNewDemand(true)
              }}
            >
              Nova demanda
            </Button>
          ]}
        />
        <ConfigProvider renderEmpty={TableEmpty}>
          <List
            itemLayout="horizontal"
            dataSource={dataSource}
            renderItem={item => (
              <List.Item
                actions={[
                  <a
                    key="detalhes"
                    onClick={() => {
                      setDemanda(item)
                      setViewDemand(true)
                    }}
                  >
                    detalhes
                  </a>,
                  <a
                    key="excluir"
                    style={{ color: 'red' }}
                    onClick={() => {
                      deleteData(item.id, 'demanda')
                    }}
                  >
                    excluir
                  </a>
                ]}
              >
                <List.Item.Meta
                  title={<Text strong>{item.nome}</Text>}
                  description={item.descricao}
                />
              </List.Item>
            )}
          />
        </ConfigProvider>
      </main>
    </div>
  )
}

export default Demandas
