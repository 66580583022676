import styled, { createGlobalStyle } from 'styled-components'

export const MenuSide = styled.div`
  display: flex;
  flex-direction: column;
  align-content: space-between;
  justify-content: space-between;
  z-index: 999;
  position: sticky;
  top: 0;
`

export const Logo = styled.div`
  padding: 10px 14px;
  background: #143a7f;
  text-align: center;
`

export const MenuCollapsed = styled.div`
  background: #ffffff;
  box-shadow: inset 0px 1px 0px rgb(240, 240, 240);
  padding: 17px 27px;
  text-align: center;
`

export const MenuHead = styled.div`
  background-color: #071832;
  padding: 3px 25px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 1998;
`

export const TypeAta = styled.div`
  background-color: #f9f9f9;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  padding: 25px;
  display: flex;
  justify-content: space-around;
  text-align: center;
  & > div {
    transition: 300ms all ease;
    cursor: pointer;
  }
  & > div:hover {
    transform: scale(1.05);
    & h3 {
      color: #258ffb;
    }
  }
`

export const MenuActions = styled.div`
  display: flex;
`

export const ActionItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1890ff;
  cursor: pointer;
  & > p {
    margin: 0 30px 0 10px;
  }
  &:hover {
    opacity: 0.9;
  }
`

export const GlobalStyle = createGlobalStyle`
    body{
        background-color: #F0F2F5;
    }
    .ant-layout-header{
        z-index: 100;
    }
    .ant-page-header-ghost.header-pages {
        background-color: #FFF;
        padding: 20px 35px;
        margin: -30px -30px 30px;
    }
    .ant-page-header-heading-title, .ant-modal-title{
        color: #16407D;
    }
    .ant-alert-message{
        font-weight: 300;
    }
    .ant-typography.ant-typography-primary{
        color: #06163390;
        font-weight: 300;
    }
    .ant-typography.ant-typography-secondary{
        color: #F0F2F5;
    }
    .ant-select-selection-placeholder, input:placeholder-shown{
        font-weight: 300;
        /* font-size: 14px !important; */
    }
    .menuside{
        min-height: 100vh;
        box-shadow: 0px 0px 5px rgba(20, 58, 127, 0.1) !important;
        background-color: #FFFFFF;
    }
    .modal-new-ata .ant-layout-sider, .modal-new-ata .ant-layout-content,
    .modal-upload-ata .ant-layout-sider, .modal-upload-ata .ant-layout-content{
        background-color: #FFFFFF;
    }
    .modal-upload-ata .ant-space-item {
        width: 100%;
    }
    .modal-new-ata .ant-modal-body{
        padding: 0;
    }
    .ant-layout-sider-trigger{
        background: #FFF;
        color: #16407D;
        border-top: 1px solid #F0F2F5;
    }
    .ant-badge-count{
        box-shadow: none;
    }
    .ant-menu-item-selected, .ant-menu-item-selected a{
        font-weight: 700;
    }
    .ant-tooltip-inner a{
        color: #FFFFFF;
    }
    .ant-menu-item-selected, .ant-menu-item-selected a, .ant-menu-item-selected a:hover, .ant-menu-item a:hover, .ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover{
        color: #16407D !important;
    }
    .ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after{
        border-right: 3px solid #16407D;
    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected, .ant-menu-item:hover{
        background-color: #F0F2F5;
    }
    .ant-btn-primary, .ant-btn-primary:focus{
        background: #16407D;
        border-color: #16407D;
    }
    .ant-timeline-item.ant-timeline-item-last {
        padding-bottom: 0;
    }
    .ant-list.ant-list-split {
        background-color: #FFFFFF;
        padding: 7px 25px;
    }
    .ant-list-split .ant-list-item {
        padding: 25px 0;
    }
    .ant-tabs-content-holder {
        background: #F0F2F5;
        padding: 30px;
    }
    .ant-page-header.head-tab{
        padding: 0;
    }
    .ant-page-header.head-tab > .ant-page-header-heading{
        padding: 20px 35px 0;
    }
    .ant-page-header.head-tab .ant-tabs-nav-wrap{
        padding: 15px 35px 0;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
        color: #16407D;
        font-weight: 700;
    }
    .ant-tabs-tab.ant-tabs-tab-active{
        border-color: #16407D;
    }
    /* .ant-col.ant-form-item-label{
        align-self: center;
    } */
    
`
