/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react'
import Helmet from 'react-helmet'
import moment from 'moment'
import locale from 'antd/es/date-picker/locale/pt_BR'

// Material UI
import {
  Tag,
  Divider,
  PageHeader,
  Table,
  Space,
  Button,
  ConfigProvider,
  Input,
  Modal,
  Typography,
  Layout,
  DatePicker,
  Form,
  Select,
  Row,
  Col,
  Avatar,
  message,
  Timeline,
  Descriptions,
  Upload
} from 'antd'
import {
  PlusCircleOutlined,
  PlusSquareOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined,
  UserOutlined,
  CheckCircleOutlined,
  PaperClipOutlined,
  InboxOutlined
} from '@ant-design/icons'

import { MenuActions, ActionItem } from 'styles/global'

// Componentes
import api from 'services/api'
import TableEmpty from 'components/TableEmpty'
import { useAuth } from 'hooks/useAuth'

const { Text, Title } = Typography
const { Sider, Content } = Layout
const { TextArea } = Input
const { Option } = Select
const { confirm } = Modal
const { Dragger } = Upload

function Denuncias() {
  const route = 'denuncia'
  const { user } = useAuth()
  const selectionType = 'checkbox'
  const [data, setData] = useState([])
  const [dataSource, setDataSource] = useState(data)
  const [form] = Form.useForm()
  const [formComp] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState('')
  const [newReport, setNewReport] = useState(false)
  const [viewReport, setViewReport] = useState(false)
  const [infoExtra, setInfoExtra] = useState(false)
  const [validateReport, setValidateReport] = useState(false)
  const [selectedData, setSelectedData] = useState([])
  const [details, setDetails] = useState(null)
  const [editMode, setEditMode] = useState(false)
  const [providencias, setProvidencias] = useState([])
  const [showProv, setShowProv] = useState(false)
  const [load, setLoad] = useState(false)
  const [anexos, setAnexos] = useState([])
  const [loadDocuments, setLoadDocuments] = useState([])

  const upload = {
    name: 'file',
    multiple: true,
    action: `${process.env.REACT_APP_API}/upload`,
    method: 'POST',
    data: { pasta: 'denuncias' },

    onChange(info) {
      const { status } = info.file
      if (status !== 'uploading') {
        // console.log(info.file, info.fileList)
      }
      if (status === 'done') {
        message.success(`${info.file.name} sucesso ao fazer upload.`)
        setAnexos([
          ...anexos,
          { anexo: info.file.response.url, fileName: info.file.name }
        ])
        // console.log('attach', attach)
      } else if (status === 'error') {
        message.error(`${info.file.name} falha ao fazer upload.`)
      }
    }
  }

  // Listar atas
  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true)
        await refreshData()
      } catch {
        message.error('Erro ao carregar os dados!')
      } finally {
        setLoading(false)
      }
    }
    loadData()
  }, [])

  const refreshData = useCallback(async () => {
    await api
      .get(`/${route}?conselhoId=${user.conselhoId}`)
      .then(({ data }) => {
        setDataSource(data)
        setData(data)
      })
  }, [load])

  const deleteData = id => {
    confirm({
      title: 'Você deseja realmente excluir?',
      icon: <ExclamationCircleOutlined />,
      content: 'Essa ação é irreversível após a confirmação',
      okText: 'Confirmar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk: async () => {
        await api
          .delete(`/${route}/${id}`)
          .then(() => {
            refreshData()
            setViewReport(false)
            setLoad(!load)
          })
          .catch(() => {
            message.error('Erro ao excluir essa denúncia')
          })
      }
    })
  }

  const storeData = async () => {
    if (anexos) {
      form.setFieldsValue({ ...form, anexos })
    }
    form.validateFields().then(async values => {
      editMode
        ? await api
            .put(
              `/${route}/${details.id}?conselhoId=${user.conselhoId}`,
              values
            )
            .then(() => {
              refreshData()
              setValidateReport(false)
              form.resetFields()
              message.success('Denúncia editada com sucesso!')
              setLoad(!load)
            })
            .catch(e => {
              if (e.response.data) {
                if (e.response.data.errors) {
                  e.response.data.errors.forEach(e => message.error(e.message))
                } else {
                  message.error(e.response.data.error)
                }
              } else {
                message.error('Erro ao editar, tente novamente')
              }
            })
        : await api
            .post(`/${route}?conselhoId=${user.conselhoId}`, values)
            .then(() => {
              refreshData()
              setNewReport(false)
              form.resetFields()
              message.success('Denúncia adicionada com sucesso!')
              setLoad(!load)
            })
            .catch(e => {
              if (e.response.data) {
                if (e.response.data.errors) {
                  e.response.data.errors.forEach(e => message.error(e.message))
                } else {
                  message.error(e.response.data.error)
                }
              } else {
                message.error('Erro ao cadastrar, tente novamente')
              }
            })
    })
  }

  const storeComplemento = async () => {
    await formComp.validateFields().then(async values => {
      await api
        .post('/complemento', { ...values, denunciaId: details.id })
        .then(async () => {
          refreshData()
          setInfoExtra(false)
          formComp.resetFields()
          message.success('Informação complementar adicionada com sucesso!')
          setLoad(!load)
        })
        .catch(e => {
          if (e.response.data) {
            if (e.response.data.errors) {
              e.response.data.errors.forEach(e => message.error(e.message))
            } else {
              message.error(e.response.data.error)
            }
          } else {
            message.error('Erro ao cadastrar, tente novamente')
          }
        })
    })
  }

  const rowSelection = {
    onChange: selectedRowKeys => {
      setSelectedData(selectedRowKeys)
    }
  }

  const columns = [
    {
      title: 'Protocolo',
      dataIndex: 'protocolo',
      sorter: (a, b) => a.protocolo - b.protocolo
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: text => (
        <Tag
          color={
            text === 'aberto'
              ? `geekblue`
              : text === 'aprovado'
              ? `green`
              : text === 'nao aprovado'
              ? `volcano`
              : ``
          }
          style={{
            textTransform: 'capitalize'
          }}
        >
          {text}
        </Tag>
      )
    },
    {
      title: 'Data',
      dataIndex: 'data',
      sorter: (a, b) => new Date(a.data) - new Date(b.data),
      render: text => moment(text).format('DD/MM/YYYY HH:mm')
    },
    {
      title: 'Criado por',
      dataIndex: 'user',
      render: text => text.nome
    },
    {
      title: 'Criado em',
      dataIndex: 'created_at',
      defaultSortOrder: 'descend',
      sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
      render: text => moment(text).format('DD/MM/YYYY HH:mm')
    },
    {
      title: 'Ações',
      dataIndex: 'acoes',
      key: 'acoes',
      render: (_, record) => (
        <Space size="middle">
          <a
            onClick={() => {
              setViewReport(true)
              setDetails(record)
              setProvidencias(record.providencias)
              form.setFieldsValue({ ...record, data: moment(record.data) })
              setLoadDocuments(
                record.documento.map(doc => {
                  return {
                    uid: doc.id,
                    url: doc.url,
                    name: doc.file_name
                  }
                })
              )
            }}
          >
            detalhes
          </a>
          <a
            style={{ color: 'red' }}
            onClick={() => {
              deleteData(record.id)
            }}
          >
            excluir
          </a>
        </Space>
      )
    }
  ]

  const props = {
    showUploadList: {
      showDownloadIcon: true,
      downloadIcon: 'download',
      showRemoveIcon: true
    },
    onRemove(file) {
      api.delete(`documento/${file.uid}`).then(() => {
        message.success('Arquivo deletado com sucesso.')
      })
    }
  }

  return (
    <div>
      <Helmet>
        <title>Denúncias – SETASC</title>
      </Helmet>

      {/* Modal de Providencias */}
      <Modal
        visible={showProv}
        title="Providencias tomadas"
        destroyOnClose
        footer={[]}
        onCancel={() => setShowProv(false)}
      >
        <Timeline style={{ padding: '30px 0 25px', width: '80%' }}>
          {providencias.map(item => (
            <Timeline.Item color="#143A7F" key={item.id}>
              <p>{item.providencia}</p>
              {item.anexo && (
                <a href={item.anexo} target="_blank" rel="noreferrer">
                  <Text style={{ color: '#1890FF', cursor: 'pointer' }}>
                    <PaperClipOutlined /> Arquivo anexado
                  </Text>
                </a>
              )}

              <p style={{ color: '#00000050', paddingTop: 7 }}>
                {moment(item.created_at).format('LLL')}
              </p>
            </Timeline.Item>
          ))}
        </Timeline>
      </Modal>
      {/* Modal Detalhes */}
      <Modal
        visible={viewReport}
        title="Detalhes da denúncia"
        destroyOnClose
        footer={[]}
        onCancel={() => {
          setViewReport(false)
          setProvidencias([])
          form.resetFields()
        }}
        width={700}
      >
        {details && (
          <>
            <Descriptions>
              <Descriptions.Item
                label="Protocolo"
                span={2}
                style={{ paddingBottom: 0 }}
              >
                <p style={{ color: '#00000050' }}>{details.protocolo}</p>
              </Descriptions.Item>
              <Descriptions.Item label="" style={{ paddingBottom: 0 }}>
                <Tag
                  color={
                    details.status === 'aberto'
                      ? `geekblue`
                      : details.status === 'aprovado'
                      ? `green`
                      : details.status === 'nao aprovado'
                      ? `volcano`
                      : ``
                  }
                  style={{
                    textTransform: 'capitalize'
                  }}
                >
                  {details.status}
                </Tag>
              </Descriptions.Item>
            </Descriptions>
            <Descriptions>
              <Descriptions.Item label="Criado em" span={2}>
                <p style={{ color: '#00000050' }}>
                  {moment(details.created_at).format('LLL')}
                </p>
              </Descriptions.Item>
              <Descriptions.Item label="Criado por">
                <p style={{ color: '#00000050' }}>{details.user.nome}</p>
              </Descriptions.Item>
            </Descriptions>
            {details.status !== 'aprovado' ? (
              <MenuActions>
                <ActionItem
                  onClick={() => {
                    setEditMode(true)
                    setValidateReport(true)
                  }}
                >
                  <CheckCircleOutlined />
                  <p>Validar Denúncia</p>
                </ActionItem>
                <ActionItem
                  style={{ color: 'red' }}
                  onClick={() => {
                    deleteData(details.id)
                  }}
                >
                  <DeleteOutlined />
                  <p>Excluir</p>
                </ActionItem>
              </MenuActions>
            ) : (
              <Descriptions column={1}>
                <Descriptions.Item label="Justificativa" span={2}>
                  {details.justificativa}
                </Descriptions.Item>
                {providencias.length > 0 ? (
                  <MenuActions>
                    <ActionItem onClick={() => setShowProv(true)}>
                      <CheckCircleOutlined />
                      <p>Providencias tomadas</p>
                    </ActionItem>
                  </MenuActions>
                ) : null}
              </Descriptions>
            )}
            <Divider />
            <Descriptions layout="vertical">
              <Descriptions.Item
                label="Data do ocorrido"
                style={{ paddingBottom: 7 }}
              >
                <Title level={4}>{moment(details.data).format('ll')}</Title>
              </Descriptions.Item>
              <Descriptions.Item label="Hora" style={{ paddingBottom: 7 }}>
                <Title level={4}>
                  {moment(details.data).format('HH[h] MM[min]')}
                </Title>
              </Descriptions.Item>
            </Descriptions>
            <div
              style={{ padding: '15px 0 45px' }}
              dangerouslySetInnerHTML={{
                __html: details.relato
              }}
            />
            <Text strong style={{ color: '#143A7F' }}>
              Informações complementares
            </Text>
            <MenuActions style={{ paddingTop: 20 }}>
              <ActionItem
                onClick={() => {
                  setInfoExtra(true)
                }}
              >
                <PlusSquareOutlined />
                <p>Adicionar informações complementares</p>
              </ActionItem>
            </MenuActions>
            <Timeline style={{ padding: '30px 0 25px', width: '80%' }}>
              {details.complementos.map(complemento => (
                <Timeline.Item color="#143A7F" key={complemento.id}>
                  {complemento.informacoes}
                  <p style={{ color: '#00000050', paddingTop: 7 }}>
                    {`Adicionado dia ${moment(complemento.createdAt).format(
                      'LLL'
                    )}`}
                  </p>
                </Timeline.Item>
              ))}
            </Timeline>
            <Upload
              {...props}
              listType="picture"
              defaultFileList={loadDocuments}
            ></Upload>
          </>
        )}
      </Modal>

      {/* Modal de Informação complementar */}
      <Modal
        visible={infoExtra}
        destroyOnClose
        title="Informação complementar"
        footer={[
          <Button
            key="back"
            onClick={() => {
              formComp.resetFields()
              setInfoExtra(false)
            }}
          >
            Cancelar
          </Button>,
          <Button key="submit" type="primary" onClick={storeComplemento}>
            Adicionar
          </Button>
        ]}
        onCancel={() => {
          formComp.resetFields()
          setInfoExtra(false)
        }}
      >
        <Form form={formComp} layout="vertical">
          <Form.Item label="Informação complementar" name="informacoes">
            <TextArea
              size="large"
              placeholder="Descreva sobre informação adicional"
              autoSize={{ minRows: 5, maxRows: 8 }}
            />
          </Form.Item>
        </Form>
      </Modal>

      {/* Modal de Validação */}
      <Modal
        visible={validateReport}
        title="Validar"
        destroyOnClose
        footer={[
          <Button
            key="back"
            onClick={() => {
              setValidateReport(false)
              form.resetFields()
            }}
          >
            Cancelar
          </Button>,
          <Button key="submit" type="primary" onClick={storeData}>
            Confirmar
          </Button>
        ]}
        onCancel={() => {
          setValidateReport(false)
          form.resetFields()
        }}
      >
        {details && (
          <Form name="justificativa" layout="vertical" form={form}>
            <Row gutter={20}>
              <Col span={12}>
                <Form.Item label="Status" name="status">
                  <Select>
                    <Option value="aberto">Aberto</Option>
                    <Option value="aprovado">Aprovado</Option>
                    <Option value="nao aprovado">Não Aprovado</Option>
                    <Option value="finalizado">Finalizado</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Prioridade" name="prioridade">
                  <Select>
                    <Option value="urgente">Urgente</Option>
                    <Option value="normal">Normal</Option>
                    <Option value="prioritaria">Prioritaria</Option>
                    <Option value="grave">Grave</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item name="relato" style={{ display: 'none' }}>
              <TextArea />
            </Form.Item>
            <Form.Item name="data" style={{ display: 'none' }}>
              <Input />
            </Form.Item>
            <Form.Item
              label="Justificativa da validação"
              name="justificativa"
              rules={[
                {
                  required: true,
                  message: 'Informe a justificativa'
                }
              ]}
              tooltip="Você precisa escrever uma justificativa sobre a denúncia"
            >
              <TextArea
                size="large"
                placeholder="Descreva a justificativa para essa denúncia"
                autoSize={{ minRows: 5, maxRows: 8 }}
              />
            </Form.Item>
          </Form>
        )}
      </Modal>

      {/* Modal de Nova Denúncia */}
      <Modal
        visible={newReport}
        width={800}
        destroyOnClose
        title="Nova denúncia"
        footer={[
          <Button
            key="back"
            onClick={() => {
              setNewReport(false)
              form.resetFields()
            }}
          >
            Cancelar
          </Button>,
          <Button key="submit" type="primary" onClick={storeData}>
            Confirmar
          </Button>
        ]}
        onCancel={() => {
          setNewReport(false)
          form.resetFields()
        }}
        className="modal-new-ata"
      >
        <Form
          name="newReport"
          layout="vertical"
          form={form}
          initialValues={{
            status: 'aberto',
            prioridade: 'normal',
            criadoPor: user.id,
            justificativa: 'Sem justificativa, ainda!'
          }}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Layout>
            <Sider
              style={{ padding: 25, borderRight: '1px solid #F0F0F0' }}
              width="300"
            >
              <Form.Item label="Protocolo" name="protocolo">
                <Input placeholder="Número Automático" size="large" disabled />
              </Form.Item>
              <Form.Item
                label="Status"
                name="status"
                style={{ display: 'none' }}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Prioridade"
                name="prioridade"
                style={{ display: 'none' }}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Data"
                name="data"
                rules={[
                  {
                    required: true,
                    message: 'Informe a data'
                  }
                ]}
              >
                <DatePicker
                  placeholder="Selecione a data"
                  size="large"
                  locale={locale}
                  style={{ width: '100%' }}
                  format="DD/MM/YYYY HH:mm"
                  showTime={{ defaultValue: moment('08:00', 'HH:mm') }}
                />
              </Form.Item>
              <Form.Item label="Criado Por" name="criadoPor">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar
                    size={30}
                    src={user.foto}
                    icon={<UserOutlined />}
                    style={{ marginRight: 15 }}
                  />
                  <Text strong>{user.nome}</Text>
                </div>
                <Input style={{ display: 'none' }} />
              </Form.Item>
            </Sider>
            <Content style={{ padding: 25 }}>
              <Form.Item
                label="Relato"
                name="relato"
                rules={[
                  {
                    required: true,
                    message: 'Informe o relato'
                  }
                ]}
              >
                <TextArea
                  size="large"
                  placeholder="Descreva a denúncia"
                  autoSize={{ minRows: 13, maxRows: 13 }}
                  style={{ height: '-webkit-fill-available' }}
                />
              </Form.Item>
              <Form.Item label="Incluir anexo" name="anexos">
                <Dragger {...upload}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Clique ou arraste o(s) arquivo(s)
                  </p>
                  <p className="ant-upload-hint">
                    Upload de um ou mais arquivo(s)
                  </p>
                </Dragger>
              </Form.Item>
              <Form.Item name="justificativa">
                <TextArea
                  style={{ display: 'none' }}
                  size="large"
                  placeholder="Descreva a justificativa para essa denúncia"
                  autoSize={{ minRows: 5, maxRows: 8 }}
                />
              </Form.Item>
            </Content>
          </Layout>
        </Form>
      </Modal>

      <main>
        <PageHeader
          className="header-pages"
          title="Denúncias"
          subTitle={`${data.length} registros no total`}
          extra={[
            <Input.Search
              allowClear
              key="search"
              style={{ width: 'fit-content' }}
              defaultValue=""
              placeholder="Pesquisar"
              value={value}
              onChange={e => {
                const currValue = e.target.value
                setValue(currValue)
                const filteredData = data.filter(entry =>
                  entry.protocolo.includes(currValue)
                )
                setDataSource(filteredData)
              }}
            />,
            // <Dropdown
            //   overlay={
            //     <Menu>
            //       <Menu.Item key="2">
            //         <a
            //           onClick={() => {
            //             ModalDelete(rowSelection.selectedRowKeys);
            //           }}
            //         >
            //           Excluir selecionados
            //         </a>
            //       </Menu.Item>
            //     </Menu>
            //   }
            // >
            //   <Button icon={<EllipsisOutlined />}></Button>
            // </Dropdown>,
            <Button
              key="1"
              type="primary"
              icon={<PlusCircleOutlined />}
              size="medium"
              onClick={() => {
                setNewReport(true)
                form.resetFields()
                setEditMode(false)
              }}
            >
              Nova denúncia
            </Button>
          ]}
        />
        <ConfigProvider renderEmpty={TableEmpty}>
          <Table
            dataSource={dataSource}
            loading={loading}
            rowKey={record => record.id}
            rowSelection={{
              type: selectionType,
              ...rowSelection
            }}
            columns={columns}
            pagination={{ PageSize: 10 }}
          />
        </ConfigProvider>
      </main>
    </div>
  )
}

export default Denuncias
