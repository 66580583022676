import React from 'react'
import { Empty } from 'antd'

const TableEmpty = () => (
  <Empty
    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
    imageStyle={{
      height: 80
    }}
    description={<span>Sem registro ainda</span>}
    style={{
      padding: 30
    }}
  >
    {/* <Button type="primary">Criar novo</Button> */}
  </Empty>
)

export default TableEmpty
