import React from 'react'
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  info: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start'
  },
  headerTitle: {
    fontSize: 24,
    borderBottomWidth: 1
  },
  headerInfo: {
    fontSize: 12,
    marginVertical: 1
  },
  body: {
    paddingTop: 20,
    paddingBottom: 30,
    paddingHorizontal: 20
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Oswald'
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: 'Oswald'
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman'
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey'
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 15,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey'
  }
})

const MyDocument = ({ dados }) => {
  return (
    <Document>
      <Page style={styles.body}>
        <>
          <View
            style={{ ...styles.info, borderBottom: 1, marginBottom: '16px' }}
          >
            <Text style={{ ...styles.headerTitle, marginBottom: '8px' }}>
              SETASC - MT
            </Text>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: '4px'
              }}
            >
              <Text style={styles.headerInfo}>
                Conselho: {dados.conselho.conselho}
              </Text>
              <Text style={styles.headerInfo}>Numero ATA: {dados.numero}</Text>
            </View>
            <View
              style={{ flexDirection: 'row', justifyContent: 'space-between' }}
            >
              <Text style={styles.headerInfo}>
                Criado por: {dados.criadoPor}
              </Text>
              <Text style={styles.headerInfo}>
                Data:{' '}
                {new Date(dados.data).toLocaleDateString('pt-BR', {
                  day: '2-digit',
                  month: 'long',
                  year: 'numeric'
                })}
              </Text>
            </View>
          </View>
          <View>
            <Text
              style={{
                fontSize: 16,
                borderBottom: 1,
                marginTop: '5px',
                marginHorizontal: '24px'
              }}
            >
              Participantes
            </Text>
            {dados.participantes.map(participante => {
              return (
                <View
                  key={participante.id}
                  style={{
                    borderBottom: 1,
                    borderStyle: 'dotted',
                    marginTop: '5px',
                    marginBottom: '12px',
                    marginHorizontal: '24px'
                  }}
                >
                  <View
                    style={{
                      flexDirection: 'row',
                      fontSize: 12,
                      justifyContent: 'space-between'
                    }}
                  >
                    <Text>{participante.nome}</Text>
                    <Text>{participante.email}</Text>
                  </View>
                </View>
              )
            })}
          </View>
          <View style={{ ...styles.info }}>
            <Text
              style={{
                fontSize: 16,
                borderBottom: 1,
                marginBottom: '8px',
                borderStyle: 'dotted'
              }}
            >
              DEMANDAS
            </Text>
            {dados.demandas.map(demanda => {
              return (
                <View
                  key={demanda.id}
                  style={{
                    borderBottom: 1,
                    borderStyle: 'dotted',
                    marginBottom: '8px'
                  }}
                >
                  <View style={{ flexDirection: 'row', fontSize: 12 }}>
                    <Text>{demanda.titulo}</Text>
                  </View>
                  <Text style={{ fontSize: 8 }}>{demanda.descricao}</Text>
                </View>
              )
            })}
          </View>
          <View style={{ marginVertical: '14px' }}>
            <Text
              style={{
                fontSize: 16,
                borderBottom: 1,
                paddingBottom: '5px',
                marginBottom: '15px'
              }}
            >
              Registro da Ata
            </Text>
            <Text style={{ fontSize: '12px' }}>{dados.registro}</Text>
          </View>
        </>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  )
}

export default MyDocument
