import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import api from 'services/api'
import axios from 'axios'
import { MaskPhone } from 'services/utils'
import moment from 'moment'
import locale from 'antd/es/date-picker/locale/pt_BR'

// Material UI
import {
  Row,
  Col,
  PageHeader,
  Button,
  Input,
  Select,
  DatePicker,
  Modal,
  Typography,
  Upload,
  Form,
  Card,
  Divider,
  Badge,
  Statistic,
  Space,
  Tag,
  Progress,
  Tooltip,
  Avatar,
  message,
  Collapse
} from 'antd'
import {
  PlusCircleOutlined,
  EnvironmentOutlined,
  EditOutlined,
  InfoCircleOutlined,
  DeleteOutlined,
  UploadOutlined,
  UserOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons'

// Componentes
import TableEmpty from 'components/TableEmpty'
import { useAuth } from 'hooks/useAuth'

const { Text } = Typography
const { Meta } = Card
const { confirm } = Modal

const colors = {
  Presidente: { name: 'Presidente', color: 'geekblue' },
  Conselheiro: { name: 'Conselheiro', color: 'volcano' },
  Suplente: { name: 'Suplente', color: 'green' }
}

function Administracao() {
  const { user } = useAuth()

  const [cities, setCities] = useState([])
  const [conselhos, setConselhos] = useState([])
  const [filtered, setFiltered] = useState([])
  const [search, setSearch] = useState('')
  const [modal, setModal] = useState('nenhum') // modais: nenhum, new_user, new_council, edit_user, edit_council
  const [cargos, setCargos] = useState([])
  const [perfis, setPerfis] = useState([])

  const [userCounsil, setUserCounsil] = useState(null)
  const [userForm] = Form.useForm()

  const [council, setCouncil] = useState(null)
  const [councilForm] = Form.useForm()

  const [load, setLoad] = useState(false)

  const upload = {
    name: 'file',
    multiple: true,
    action: `${process.env.REACT_APP_API}/upload`,
    method: 'POST',
    data: { pasta: 'fotos' },
    onChange: info => {
      const { status } = info.file
      if (status === 'done') {
        message.success(`${info.file.name} sucesso ao fazer upload.`)
        userForm.setFieldsValue({ foto: info.file.response.url })
      } else if (status === 'error') {
        message.error(`${info.file.name} falha ao fazer upload.`)
      }
    }
  }

  const uploadAssinatura = {
    name: 'file',
    multiple: true,
    action: `${process.env.REACT_APP_API}/upload`,
    method: 'POST',
    data: { pasta: 'assinaturas' },
    onChange: info => {
      const { status } = info.file
      if (status === 'done') {
        message.success(`${info.file.name} sucesso ao fazer upload.`)
        userForm.setFieldsValue({ assinatura: info.file.response.url })
      } else if (status === 'error') {
        message.error(`${info.file.name} falha ao fazer upload.`)
      }
    }
  }

  useEffect(() => {
    async function loadCouncil() {
      try {
        await api.get(`/conselho`).then(async ({ data }) => {
          console.log(data)
          setConselhos(data)
          setFiltered(data)
          loadCities()
        })
      } catch (error) {
        console.log(error)
        console.error('Erro ao carregar os dados, atualize a pagina')
      }
    }
    loadCouncil()
  }, [load])

  const deleteData = (id, route) => {
    confirm({
      title: 'Você deseja realmente excluir?',
      icon: <ExclamationCircleOutlined />,
      content: 'Essa ação é irreversível após a confirmação',
      okText: 'Confirmar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk: async () => {
        await api
          .delete(`/${route}/${id}`)
          .then(() => {
            setLoad(!load)
          })
          .catch(e => {
            if (e.response.data) {
              if (e.response.data.errors) {
                e.response.data.errors.forEach(e => message.error(e.message))
              } else {
                message.error(e.response.data.error)
              }
            } else {
              message.error('Erro ao excluir, tente novamente')
            }
          })
      }
    })
  }

  const onCancel = () => {
    setModal('nenhum')
    setCouncil(null)
    councilForm.resetFields()
    setUserCounsil(null)
    userForm.resetFields()
    setLoad(!load)
  }

  const loadCargos = async idConselho => {
    if (idConselho) {
      await api
        .get(`/cargo?conselhoId=${idConselho}`)
        .then(async ({ data }) => {
          setCargos(data)
        })
    } else {
      await api
        .get(`/cargo?conselhoId=${user.conselhoId}`)
        .then(async ({ data }) => {
          setCargos(data)
        })
    }
  }

  const loadPerfis = async () => {
    await api
      .get(`/perfil?conselhoId=${user.conselhoId}`)
      .then(async ({ data }) => {
        setPerfis(data)
      })
  }

  const loadCities = async () => {
    await axios
      .get(
        'https://servicodados.ibge.gov.br/api/v1/localidades/estados/51/municipios'
      )
      .then(res => {
        setCities(res.data)
      })
  }

  const onSaveUser = async () => {
    await userForm.validateFields().then(async data => {
      if (modal === 'edit_user') {
        await api
          .put(`/usuario/${userCounsil.id}`, data)
          .then(() => {
            message.success('Usuario editado com sucesso!')
            onCancel()
          })
          .catch(e => {
            if (e.response.data) {
              if (e.response.data.errors) {
                e.response.data.errors.forEach(e => message.error(e.message))
              } else {
                message.error(e.response.data.error)
              }
            } else {
              message.error('Erro ao editar, tente novamente')
            }
          })
      } else {
        await api
          .post(`/usuario`, data)
          .then(() => {
            message.success('Usuario criado com sucesso!')
            onCancel()
          })
          .catch(e => {
            if (e.response.data) {
              if (e.response.data.errors) {
                e.response.data.errors.forEach(e => message.error(e.message))
              } else {
                message.error(e.response.data.error)
              }
            } else {
              message.error('Erro ao salvar, tente novamente')
            }
          })
      }
    })
  }

  const onSaveCounsil = async () => {
    await councilForm.validateFields().then(async data => {
      if (modal === 'edit_council') {
        await api
          .put(`/conselho/${council.id}`, data)
          .then(() => {
            message.success('Conselho editado com sucesso!')
            onCancel()
          })
          .catch(e => {
            if (e.response.data) {
              if (e.response.data.errors) {
                e.response.data.errors.forEach(e => message.error(e.message))
              } else {
                message.error(e.response.data.error)
              }
            } else {
              message.error('Erro ao editar, tente novamente')
            }
          })
      } else {
        await api
          .post('/conselho', data)
          .then(() => {
            message.success('Conselho cadastrado com sucesso!')
            onCancel()
          })
          .catch(e => {
            if (e.response.data) {
              if (e.response.data.errors) {
                e.response.data.errors.forEach(e => message.error(e.message))
              } else {
                message.error(e.response.data.error)
              }
            } else {
              message.error('Erro ao salvar, tente novamente')
            }
          })
      }
    })
  }

  return (
    <div>
      <Helmet>
        <title>Administração – SETASC</title>
      </Helmet>
      {/* form conselho */}
      <Modal
        visible={modal === 'new_council' || modal === 'edit_council'}
        title={
          modal === 'edit_council' ? 'Atualizar conselho' : 'Novo conselho'
        }
        destroyOnClose
        footer={[
          <Button key="back" onClick={onCancel}>
            Cancelar
          </Button>,
          <Button key="submit" type="primary" onClick={onSaveCounsil}>
            Confirmar
          </Button>
        ]}
        onCancel={onCancel}
      >
        <Form
          form={councilForm}
          layout="vertical"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Form.Item label="Nome do Conselho" name="conselho">
            <Input placeholder="Nome do conselho" size="large" />
          </Form.Item>

          <Form.Item label="Cidade" name="cidade">
            <Select
              placeholder="Selecione..."
              size="large"
              optionFilterProp="children"
              showSearch
            >
              {cities.map(city => (
                <Select.Option key={city.nome} value={city.nome}>
                  {city.nome}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Sigla" name="sigla">
            <Input placeholder="Digite a abreviação do conselho" size="large" />
          </Form.Item>

          <Form.Item label="Última eleição" name="ultimaEleicao">
            <DatePicker
              placeholder="Selecione a data"
              size="large"
              style={{ width: '100%' }}
              locale={locale}
              format="DD/MM/YYYY"
            />
          </Form.Item>
        </Form>
      </Modal>
      {/* form usuarios */}
      <Modal
        visible={modal === 'new_user' || modal === 'edit_user'}
        title={modal === 'edit_user' ? 'Atualizar Usuario' : 'Novo Usuario'}
        destroyOnClose
        width={800}
        footer={[
          <Button key="back1" onClick={onCancel}>
            Cancelar
          </Button>,
          <Button key="submit2" type="primary" onClick={onSaveUser}>
            Confirmar
          </Button>
        ]}
        onCancel={onCancel}
      >
        <Form
          form={userForm}
          layout="vertical"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Row gutter={30} wrap>
            <Col span={12}>
              <Form.Item label="Nome" name="nome">
                <Input placeholder="Nome do usuário" size="large" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Email" name="email">
                <Input placeholder="Email" size="large" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Conselho" name="conselhoId">
                <Select
                  placeholder="Selecione..."
                  size="large"
                  optionFilterProp="children"
                  showSearch
                  onChange={id => {
                    loadCargos(id)
                    loadPerfis()
                  }}
                >
                  {conselhos.map(council => {
                    return (
                      <Select.Option key={council.id} value={council.id}>
                        {council.conselho}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Celular"
                name="telefone"
                normalize={value => MaskPhone(value)}
              >
                <Input placeholder="Celular" size="large" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Cargo" name="cargoId">
                <Select
                  placeholder="Selecione..."
                  size="large"
                  optionFilterProp="children"
                >
                  {cargos.map(cargo => {
                    return (
                      <Select.Option key={cargo.id} value={cargo.id}>
                        {cargo.cargo}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Foto de perfil" name="label_foto">
                <Upload {...upload} listType="picture" maxCount={1}>
                  <Button icon={<UploadOutlined />}>Adicionar foto</Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Permissões" name="perfilId">
                <Select
                  placeholder="Selecione..."
                  size="large"
                  optionFilterProp="children"
                >
                  {perfis.map(perfil => {
                    return (
                      <Select.Option key={perfil.id} value={perfil.id}>
                        {perfil.perfil}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Assinatura"
                name="assinatura"
                tooltip={{
                  title:
                    'Assinatura é utilizada ao gerar pdf de um arquivo oficial',
                  icon: <InfoCircleOutlined />
                }}
              >
                <Upload {...uploadAssinatura} listType="picture" maxCount={1}>
                  <Button icon={<UploadOutlined />}>
                    Adicionar assinatura
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <main>
        <PageHeader
          className="header-pages"
          title="Administração"
          extra={[
            <Button
              key="1"
              type="secondary"
              size="medium"
              onClick={() => setModal('new_council')}
            >
              Adicionar conselho
            </Button>,
            <Button
              key="2"
              type="primary"
              icon={<PlusCircleOutlined />}
              size="medium"
              onClick={() => setModal('new_user')}
            >
              Nova usuário
            </Button>
          ]}
        />
        <Row gutter={30}>
          <Col span={16}>
            <Input.Search
              allowClear
              style={{ width: '100%' }}
              size="large"
              placeholder="Pesquisar"
              value={search}
              onChange={e => {
                const currValue = e.target.value.toLowerCase()
                setSearch(currValue)
                const filteredData = conselhos.filter(entry =>
                  entry.conselho.toLowerCase().includes(currValue)
                )
                setFiltered(filteredData)
              }}
            />
          </Col>
          <Col span={8}>
            <Form name="cidade" layout="horizontal">
              <Form.Item label="Cidade" name="cidade">
                <Select
                  placeholder="Selecione..."
                  size="large"
                  optionFilterProp="children"
                  showSearch
                  onChange={currValue => {
                    if (currValue !== 'Todos') {
                      const filteredData = conselhos.filter(entry =>
                        entry.cidade.includes(currValue)
                      )
                      setFiltered(filteredData)
                    } else {
                      setFiltered(conselhos)
                    }
                  }}
                >
                  <Select.Option key="Todos" value="Todos">
                    Todas
                  </Select.Option>
                  {cities.map(city => (
                    <Select.Option key={city.nome} value={city.nome}>
                      {city.nome}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Space direction="vertical" size={30} wrap style={{ width: '100%' }}>
          {filtered.length === 0 ? (
            <TableEmpty />
          ) : (
            <Collapse accordion defaultActiveKey={user.conselhoId}>
              {filtered.map(item => {
                return (
                  <Collapse.Panel
                    key={item.id}
                    header={
                      <Text strong>
                        {item.conselho}
                        <Text style={{ marginLeft: 20, color: '#00000070' }}>
                          <EnvironmentOutlined /> {item.cidade}
                        </Text>
                      </Text>
                    }
                    extra={
                      <a
                        onClick={() => {
                          setCouncil(item)
                          councilForm.setFieldsValue({
                            ...item,
                            ultimaEleicao: moment(item.ultimaEleicao)
                          })
                          setModal('edit_council')
                        }}
                      >
                        Editar
                      </a>
                    }
                  >
                    <Row gutter={30}>
                      <Col key="1" span={6}>
                        <Card>
                          <p>O {item.conselho} está com:</p>
                          <Row
                            gutter={30}
                            justify="space-around"
                            align="middle"
                          >
                            <Col>
                              <Statistic
                                title={
                                  <span>
                                    <Badge status="processing" /> Conselheiros
                                  </span>
                                }
                                value={item.conselheiros?.length}
                                style={{ textAlign: 'right' }}
                              />
                            </Col>
                            <Col>
                              <Statistic
                                title={
                                  <span>
                                    <Badge status="warning" /> Denúncias
                                  </span>
                                }
                                value={item.denuncias?.length}
                                style={{ textAlign: 'right' }}
                              />
                            </Col>
                          </Row>
                          <Divider />
                          <Row>
                            <Col>
                              <Text strong style={{ color: '#143A7F' }}>
                                Período eleitoral
                                <Tooltip title="Dias para encerrar a gestão dessa chapa">
                                  <span style={{ paddingLeft: 5 }}>
                                    <InfoCircleOutlined />
                                  </span>
                                </Tooltip>
                              </Text>
                              <p style={{ padding: '10px 0' }}>
                                Uma nova eleição deve ser feita após{' '}
                                <Text strong>XXX dias</Text>
                              </p>
                              <Progress percent={30} />
                            </Col>
                          </Row>
                          <Divider />
                        </Card>
                      </Col>
                      <Col key="2" span={18}>
                        <Row gutter={[30, 30]} wrap>
                          {item.usuarios &&
                            item.usuarios?.map(user => {
                              return (
                                <Col span={8} key={`col${user.id}`}>
                                  <Card
                                    key={user.id}
                                    actions={[
                                      <a
                                        key={`editar${user.id}`}
                                        style={{ color: '#1890FF' }}
                                        onClick={() => {
                                          loadPerfis()
                                          loadCargos()
                                          setUserCounsil(user)
                                          userForm.setFieldsValue(user)
                                          setModal('edit_user')
                                        }}
                                      >
                                        <EditOutlined key="edit" /> Editar
                                      </a>,
                                      <a
                                        key={`excluir${user.id}`}
                                        style={{ color: '#FF4D4F' }}
                                        onClick={() => {
                                          deleteData(user.id, 'usuario')
                                        }}
                                      >
                                        <DeleteOutlined key="excluir" /> Excluir
                                      </a>
                                    ]}
                                  >
                                    <Meta
                                      avatar={
                                        user.foto ? (
                                          <Avatar src={user.foto} />
                                        ) : (
                                          <Avatar
                                            style={{
                                              backgroundColor: '#143A7F'
                                            }}
                                            icon={<UserOutlined />}
                                          />
                                        )
                                      }
                                      title={user.nome}
                                      description={
                                        <span>
                                          <p>{user.email}</p>
                                          {user.cargo.cargo && (
                                            <Tag>{user.cargo.cargo}</Tag>
                                          )}
                                        </span>
                                      }
                                    />
                                  </Card>
                                </Col>
                              )
                            })}
                        </Row>
                      </Col>
                    </Row>
                  </Collapse.Panel>
                )
              })}
            </Collapse>
          )}
        </Space>
      </main>
    </div>
  )
}

export default Administracao
