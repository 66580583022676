import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Form, Input, Button, message, Spin } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import api from 'services/api'

function AlterarSenha() {
  const [form] = Form.useForm()
  const { token, id } = useParams()
  console.log(token)
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const storageToken = localStorage.getItem('TOKEN_SETASC_API')
  if (!storageToken) {
    localStorage.setItem('TOKEN_SETASC_API', token)
  } else {
    localStorage.clear()
    localStorage.setItem('TOKEN_SETASC_API', token)
  }

  return (
    <Container>
      <Content>
        <FormContainer>
          <Spin spinning={loading}>
            <Form
              orientation="left"
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 16 }}
              form={form}
              style={{ width: '100%' }}
              onFinish={async ({ password }) => {
                setLoading(true)
                await api
                  .put(`/usuario/${id}`, { password, ativo: true })
                  .then(() => {
                    setLoading(false)
                    localStorage.clear()
                    history.push('/')
                  })
                  .catch(e => {
                    message.error(e.response.data.error)
                    setLoading(false)
                  })
              }}
            >
              <Form.Item
                name="password"
                label="Senha"
                rules={[
                  {
                    required: true,
                    message: 'A senha deve ser informada'
                  }
                ]}
                hasFeedback
              >
                <Input.Password size="middle" />
              </Form.Item>
              <Form.Item
                name="confirm"
                label="Confirme a senha"
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Confirme sua senha'
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve()
                      }
                      return Promise.reject(
                        new Error('As senhas devem ser iguais!')
                      )
                    }
                  })
                ]}
              >
                <Input.Password size="middle" />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 16 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  Trocar senha
                </Button>
              </Form.Item>
            </Form>
          </Spin>
        </FormContainer>
      </Content>
    </Container>
  )
}

const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d9d9d9;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
`
const Content = styled.div`
  display: flex;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  height: 400px;
  width: 500px;
  border-radius: 10px;
  overflow: hidden;
  @media (max-width: 980px) {
    width: 400px;
  }
`

const FormContainer = styled.div`
  height: 90%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  @media (max-width: 980px) {
    width: 100%;
  }
`

const LogoContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export default AlterarSenha
