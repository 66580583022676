import React, { useState, useEffect, useCallback } from 'react'
import Helmet from 'react-helmet'
import moment from 'moment'

// Material UI
import {
  Row,
  Col,
  Divider,
  PageHeader,
  Table,
  Space,
  Button,
  ConfigProvider,
  Input,
  Dropdown,
  Menu,
  Modal,
  Typography,
  message,
  Form,
  Descriptions,
  Upload
} from 'antd'
import {
  PlusCircleOutlined,
  EllipsisOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  EditOutlined,
  InboxOutlined
} from '@ant-design/icons'

import { MenuActions, ActionItem } from 'styles/global'

// Componentes
import api from 'services/api'
import TableEmpty from 'components/TableEmpty'
import { useAuth } from 'hooks/useAuth'

const { Title, Text } = Typography
const { TextArea } = Input
const { confirm } = Modal
const { Dragger } = Upload

function Notas() {
  const selectionType = 'checkbox'
  const route = 'nota'
  const { user } = useAuth()
  const [data, setData] = useState([])
  const [dataSource, setDataSource] = useState(data)
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [load, setLoad] = useState(false)
  const [search, setSearch] = useState('')
  const [newNote, setNewNote] = useState(false)
  const [viewNote, setViewNote] = useState(false)
  const [selectedData, setSelectedData] = useState()
  const [details, setDetails] = useState(null)
  const [editMode, setEditMode] = useState(false)
  const [anexos, setAnexos] = useState('')
  const [loadDocuments, setLoadDocuments] = useState([])

  // Listar atas
  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true)
        await refreshData()
      } catch {
        message.error('Erro ao carregar os dados, atualize a página')
      } finally {
        setLoading(false)
      }
    }
    loadData()
  }, [load])

  const refreshData = useCallback(async () => {
    await api
      .get(`/${route}?conselhoId=${user.conselhoId}`)
      .then(({ data }) => {
        setDataSource(data)
        setData(data)
      })
  }, [])

  const deleteData = id => {
    confirm({
      title: 'Você deseja realmente excluir?',
      icon: <ExclamationCircleOutlined />,
      content: 'Essa ação é irreversível após a confirmação',
      okText: 'Confirmar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk: async () => {
        await api
          .delete(`/${route}/${id}`)
          .then(() => {
            refreshData()
            setViewNote(false)
            setLoad(!load)
          })
          .catch(() => {
            message.error('Erro ao excluir essa denúncia')
          })
      }
    })
  }

  const storeData = () => {
    if (anexos) {
      form.setFieldsValue({ ...form, anexos })
    }
    form.validateFields().then(async values => {
      editMode
        ? await api
            .put(`/${route}/${details.id}`, values)
            .then(() => {
              refreshData()
              setNewNote(false)
              setEditMode(false)
              message.success('Nota editada com sucesso!')
              setLoad(!load)
            })
            .catch(e => {
              if (e.response.data) {
                if (e.response.data.errors) {
                  e.response.data.errors.forEach(e => message.error(e.message))
                } else {
                  message.error(e.response.data.error)
                }
              } else {
                message.error('Erro ao editar, tente novamente')
              }
            })
        : await api
            .post(`/${route}?conselhoId=${user.conselhoId}`, values)
            .then(() => {
              message.success('Nota cadastrada com sucesso!')
              refreshData()
              setNewNote(false)
              form.resetFields()
              setLoad(!load)
            })
            .catch(e => {
              if (e.response.data) {
                if (e.response.data.errors) {
                  e.response.data.errors.forEach(e => message.error(e.message))
                } else {
                  message.error(e.response.data.error)
                }
              } else {
                message.error('Erro ao cadastrar, tente novamente')
              }
            })
    })
  }

  const rowSelection = {
    onChange: selectedRowKeys => {
      setSelectedData(selectedRowKeys)
    }
  }

  const columns = [
    {
      title: 'Número da nota',
      dataIndex: 'numero',
      sorter: (a, b) => a.numero - b.numero
    },
    {
      title: 'Assunto',
      dataIndex: 'assunto'
    },
    {
      title: 'Descrição',
      dataIndex: 'descricao',
      render: text => (
        <Text ellipsis={true} style={{ width: 190 }}>
          {text}
        </Text>
      )
    },
    {
      title: 'Criado por',
      dataIndex: 'autor',
      render: text => text.nome
    },
    {
      title: 'Criado em',
      dataIndex: 'createdAt',
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      render: text => moment(text).format('DD/MM/YYYY HH:mm')
    },
    {
      title: 'Ações',
      dataIndex: 'acoes',
      key: 'acoes',
      render: (_, record) => (
        <Space size="middle">
          <a
            onClick={() => {
              setDetails(record)
              setViewNote(true)
              setLoadDocuments(
                record.documento.map(doc => {
                  return {
                    uid: doc.id,
                    url: doc.url,
                    name: doc.file_name
                  }
                })
              )
            }}
          >
            detalhes
          </a>
          <a
            style={{ color: 'red' }}
            onClick={() => {
              deleteData(record.id)
            }}
          >
            excluir
          </a>
        </Space>
      )
    }
  ]

  const upload = {
    name: 'file',
    multiple: true,
    action: `${process.env.REACT_APP_API}/upload`,
    method: 'POST',
    data: { pasta: 'teste' },

    defaultFileList: loadDocuments,

    onChange(info) {
      const { status } = info.file
      if (status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (status === 'done') {
        message.success(`${info.file.name} sucesso ao fazer upload.`)
        setAnexos([
          ...anexos,
          { anexo: info.file.response.url, fileName: info.file.name }
        ])
      } else if (status === 'error') {
        message.error(`${info.file.name} falha ao fazer upload.`)
      }
    }
  }

  const props = {
    showUploadList: {
      showDownloadIcon: true,
      downloadIcon: 'download',
      showRemoveIcon: true
    },
    onRemove(file) {
      api.delete(`documento/${file.uid}`).then(() => {
        message.success('Arquivo deletado com sucesso.')
      })
    }
  }

  return (
    <div>
      <Helmet>
        <title>Notas recomendatórias – SETASC</title>
      </Helmet>

      {/* Modal de detalhes */}
      <Modal
        destroyOnClose
        visible={viewNote}
        title="Detalhes da nota recomendatória"
        footer={[]}
        onCancel={() => {
          setViewNote(false)
          setDetails(null)
          setAnexos('')
          setLoadDocuments([])
        }}
        width={800}
      >
        {details && (
          <>
            <Descriptions>
              <Descriptions.Item
                label="Número da nota"
                style={{ paddingBottom: 0 }}
              >
                <p style={{ color: '#00000050' }}>{details.numero}</p>
              </Descriptions.Item>
            </Descriptions>
            <Descriptions>
              <Descriptions.Item label="Criado em" span={2}>
                <p style={{ color: '#00000050' }}>
                  {moment(details.created_at).format('LLL')}
                </p>
              </Descriptions.Item>
              <Descriptions.Item label="Criado por">
                <p style={{ color: '#00000050' }}>{details.autor.nome}</p>
              </Descriptions.Item>
            </Descriptions>
            <MenuActions>
              <ActionItem
                onClick={() => {
                  setViewNote(false)
                  setEditMode(true)
                  setNewNote(true)
                  form.setFieldsValue(details)
                }}
              >
                <EditOutlined />
                <p>Editar</p>
              </ActionItem>
              {/* <ActionItem>
                <DownloadOutlined />
                <p>Baixar</p>
              </ActionItem> */}
              <ActionItem
                style={{ color: 'red' }}
                onClick={() => {
                  deleteData(details.id)
                }}
              >
                <DeleteOutlined />
                <p>Excluir</p>
              </ActionItem>
            </MenuActions>
            <Divider />
            <Title level={4}>{details.assunto}</Title>
            <div
              style={{ padding: '15px 0 45px' }}
              dangerouslySetInnerHTML={{
                __html: details.descricao
              }}
            />
            <Divider />

            <Upload
              {...props}
              listType="picture"
              defaultFileList={loadDocuments}
            ></Upload>
          </>
        )}
      </Modal>

      {/* Modal nova nota */}
      <Modal
        visible={newNote}
        destroyOnClose
        style={{ top: 40 }}
        width={800}
        title="Nova nota recomendatória"
        footer={[
          <Button
            key="back"
            onClick={() => {
              setNewNote(false)
              form.resetFields()
              setDetails(null)
            }}
          >
            Cancelar
          </Button>,
          <Button key="submit" type="primary" onClick={storeData}>
            Confirmar
          </Button>
        ]}
        onCancel={() => {
          setNewNote(false)
          form.resetFields()
          setDetails(null)
          setAnexos('')
          setLoadDocuments([])
        }}
      >
        <Form
          name="nova_nota"
          layout="vertical"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          form={form}
          initialValues={{
            criadoPor: user.id
          }}
        >
          <Row gutter={30}>
            <Col span={12}>
              <Form.Item label="Número da nota" name="label_numero">
                <Input placeholder="Número automático" size="large" disabled />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name="criadoPor" style={{ display: 'none' }}>
            <Input />
          </Form.Item>
          <Form.Item label="Assunto" name="assunto" required>
            <Input placeholder="Digite o assunto da nota" size="large" />
          </Form.Item>
          <Form.Item label="Descrição" name="descricao" required>
            <TextArea
              size="large"
              placeholder="Descreva sobre a nota recomendatória"
              autoSize={{ minRows: 8, maxRows: 15 }}
            />
          </Form.Item>
          <Form.Item label="Incluir anexo" name="anexos">
            <Dragger {...upload}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Clique ou arraste o(s) arquivo(s)
              </p>
              <p className="ant-upload-hint">Upload de um ou mais arquivo(s)</p>
            </Dragger>
          </Form.Item>
        </Form>
      </Modal>

      <main>
        <PageHeader
          title="Notas recomendatórias"
          subTitle={`${data.length} registros no total`}
          className="header-pages"
          extra={[
            <Input.Search
              allowClear
              key="search"
              style={{ width: 'fit-content' }}
              defaultValue=""
              placeholder="Pesquisar"
              value={search}
              onChange={e => {
                const currValue = e.target.value
                setSearch(currValue)
                const filteredData = data.filter(entry =>
                  entry.numero.includes(currValue)
                )
                setDataSource(filteredData)
              }}
            />,
            <Dropdown
              key="menu"
              overlay={
                <Menu>
                  <Menu.Item key="2">
                    <a
                      onClick={() => {
                        deleteData(rowSelection.selectedRowKeys)
                      }}
                    >
                      Excluir selecionados
                    </a>
                  </Menu.Item>
                </Menu>
              }
            >
              <Button icon={<EllipsisOutlined />}></Button>
            </Dropdown>,
            <Button
              key="1"
              type="primary"
              icon={<PlusCircleOutlined />}
              size="medium"
              onClick={() => {
                setNewNote(true)
                form.resetFields()
                setEditMode(false)
              }}
            >
              Nova nota recomendatória
            </Button>
          ]}
        />
        <ConfigProvider renderEmpty={TableEmpty}>
          <Table
            dataSource={dataSource}
            loading={loading}
            rowKey={record => record.id}
            rowSelection={{
              type: selectionType,
              ...rowSelection
            }}
            columns={columns}
            pagination={{ PageSize: 10 }}
          />
        </ConfigProvider>
      </main>
    </div>
  )
}

export default Notas
