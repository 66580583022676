import React, { useState, useEffect, useContext } from 'react'
import Helmet from 'react-helmet'
import api from 'services/api'
import { format, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import AuthContext from '../contexts/auth'
// Material UI
import {
  Row,
  Col,
  List,
  PageHeader,
  Input,
  Modal,
  Card,
  Space,
  Tag,
  Tooltip,
  Tabs,
  Progress,
  Statistic,
  Descriptions,
  Avatar,
  message
} from 'antd'
import {
  UserOutlined,
  EyeOutlined,
  InfoCircleOutlined
} from '@ant-design/icons'

// Componentes
import TableEmpty from 'components/TableEmpty'

const { TabPane } = Tabs
const { Meta } = Card

const colors = {
  Presidente: { name: 'Presidente', color: 'geekblue' },
  Titular: { name: 'Titular', color: 'geekblue' },
  Conselheiro: { name: 'Conselheiro', color: 'volcano' },
  Suplente: { name: 'Suplente', color: 'green' }
}

function Historico() {
  const { user } = useContext(AuthContext)

  const [dataUsers, setDataUsers] = useState([])
  const [dataUsersInitial, setDataUsersInitial] = useState([])
  const [valueFilterUser, setFilterUser] = useState('')
  const [viewHist, setViewHist] = useState(false)
  const [userModal, setUserModal] = useState({})

  useEffect(() => {
    async function loadData() {
      try {
        await api
          .get(`/usuario?conselhoId=${user.conselhoId}&historico=true`)
          .then(data => {
            setDataUsersInitial(data.data)
            setDataUsers(data.data)
          })
      } catch {
        message.error('Erro ao carregar os dados, atualize a página')
      }
    }
    loadData()
  }, [])

  return (
    <div>
      <Helmet>
        <title>Histórico – SETASC</title>
      </Helmet>

      <Modal
        visible={viewHist}
        title="Histórico"
        footer={[]}
        onCancel={() => {
          setViewHist(false)
        }}
        width={800}
      >
        <PageHeader
          title={userModal.nome}
          style={{ padding: 0 }}
          footer={
            <Tabs defaultActiveKey="1">
              <TabPane tab="Frequência" key="1">
                <Row gutter={30}>
                  <Col span={10}>
                    <Card>
                      <Statistic
                        title={
                          <Tooltip title="Valor total de presença confirmada em reuniões do conselho">
                            Presenças{' '}
                            <span style={{ paddingLeft: 5 }}>
                              <InfoCircleOutlined />
                            </span>
                          </Tooltip>
                        }
                        value={userModal.presencas}
                      />
                      <Progress percent={+userModal.presencas * 10} />
                    </Card>
                  </Col>
                  <Col span={14}>
                    <List itemLayout="horizontal">
                      {userModal.atas?.map(item => {
                        return (
                          <List.Item key={item.id}>
                            <List.Item.Meta
                              title={`Ata: ${item.numero}`}
                              description={`${
                                item.data
                                  ? format(
                                      parseISO(item.data),
                                      "dd 'de' MMMM' de ' yyyy' às 'HH'h",
                                      {
                                        locale: ptBR
                                      }
                                    )
                                  : ''
                              }`}
                            />
                          </List.Item>
                        )
                      })}
                    </List>
                  </Col>
                </Row>
              </TabPane>
              {/* <TabPane tab="Despesas" key="2" /> */}
            </Tabs>
          }
        >
          <div>
            <Descriptions size="small" column={3}>
              <Descriptions.Item span={3} label="">
                <Tag color="geekblue">{userModal.cargo?.cargo}</Tag>
              </Descriptions.Item>
              <Descriptions.Item span={2} label="Conselho">
                <span style={{ color: '#00000070' }}>
                  {userModal.conselho?.conselho}
                </span>
              </Descriptions.Item>
              <Descriptions.Item label="Cidade">
                <span style={{ color: '#00000070' }}>
                  {userModal.conselho?.cidade}
                </span>
              </Descriptions.Item>
              <Descriptions.Item label="E-mail">
                <a>{userModal.email}</a>
              </Descriptions.Item>
              <Descriptions.Item label="Celular">
                <a>{userModal.telefone}</a>
              </Descriptions.Item>
            </Descriptions>
          </div>
        </PageHeader>
      </Modal>

      <main>
        <PageHeader title="Histórico" className="header-pages" />
        <Space direction="vertical" size={30} wrap style={{ width: '100%' }}>
          <Input.Search
            allowClear
            style={{ width: '100%' }}
            defaultValue=""
            size="large"
            placeholder="Pesquisar por usuário"
            value={valueFilterUser}
            onChange={e => {
              const currValue = e.target.value
              const valueLower = currValue.toLowerCase()
              if (currValue !== '') {
                setFilterUser(currValue)
                const filteredData = dataUsersInitial.filter(entry =>
                  entry.nome.toLowerCase().includes(valueLower)
                )
                setDataUsers(filteredData)
              } else {
                setFilterUser(currValue)
                setDataUsers(dataUsersInitial)
              }
            }}
          />

          <Card>
            <Row gutter={30}>
              <Col span={24}>
                <Row gutter={[30, 30]} wrap>
                  {!dataUsers[0]?.nome || dataUsers.length === 0 ? (
                    <Col span={24}>
                      <TableEmpty />
                    </Col>
                  ) : (
                    dataUsers.map(user => (
                      <Col span={6} key={user.id}>
                        <Card
                          actions={[
                            <a
                              key="1"
                              style={{ color: '#1890FF' }}
                              onClick={() => {
                                setViewHist(true)
                                setUserModal(user)
                              }}
                            >
                              <EyeOutlined key="edit" /> Ver Histórico
                            </a>
                          ]}
                        >
                          <Meta
                            avatar={
                              user.foto ? (
                                <Avatar src={user.foto} />
                              ) : (
                                <Avatar
                                  style={{ backgroundColor: '#143A7F' }}
                                  icon={<UserOutlined />}
                                />
                              )
                            }
                            title={user.nome}
                            description={
                              <span>
                                <p>
                                  {user.conselho.conselho} –
                                  {user.conselho.cidade}
                                </p>
                                {user.cargo.cargo && (
                                  <Tag>{user.cargo.cargo}</Tag>
                                )}
                              </span>
                            }
                          />
                        </Card>
                      </Col>
                    ))
                  )}
                </Row>
              </Col>
            </Row>
          </Card>
        </Space>
      </main>
    </div>
  )
}

export default Historico
