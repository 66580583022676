function MaskPhone(value) {
  let clean = value.replace(/\D/g, '')
  clean = clean.replace(/^0/, '')
  if (clean.length > 10) {
    clean = clean.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3')
  }
  if (clean.length > 5) {
    clean = clean.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, '($1) $2-$3')
  }
  if (clean.length > 2) {
    clean = clean.replace(/^(\d\d)(\d{0,5})/, '($1) $2')
  } else {
    clean = clean.replace(/^(\d*)/, '($1')
  }

  return clean
}

function formatarMoeda(value) {
  const formato = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  })
  return formato.format(Number(value))
}

export { MaskPhone, formatarMoeda }
