import React, { useState } from 'react'
import Helmet from 'react-helmet'
import 'antd/dist/antd.css'
import { Switch, Redirect } from 'react-router-dom'
import { MenuHeader, MenuNav } from 'components'
import {
  Atas,
  Denuncias,
  Providencias,
  Notas,
  Prestacao,
  Demandas,
  Resolucoes,
  Administracao,
  Historico,
  Perfil
} from 'pages'
import { GlobalStyle } from 'styles/global'
import 'styles/global.css'
import { Layout } from 'antd'
import ProtectedRoute from './protected'

const { Header, Sider, Content } = Layout

function App() {
  const [collapsed, setCollapsed] = useState(false)

  const toggleCollapsed = () => {
    setCollapsed(!collapsed)
  }

  return (
    <>
      <Helmet>
        <title>SETASC</title>
        <meta name="theme-color" content="#183181" />
        <meta name="title" content="SETASC" />
        <meta
          name="description"
          content="Secretaria de Estado de Assistência Social e Cidadania"
        />
        <meta name="robots" content="noindex, nofollow" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="Portuguese" />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#183181" />
        <meta name="msapplication-TileColor" content="#183181" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>
      <Layout>
        <Sider
          width={250}
          collapsible
          collapsed={collapsed}
          onCollapse={toggleCollapsed}
          className="menuside"
        >
          <MenuNav type={collapsed} />
        </Sider>
        <Layout>
          <Header
            style={{ padding: 0, height: 'auto', position: 'sticky', top: 0 }}
          >
            <MenuHeader />
          </Header>
          <Content style={{ padding: 30 }}>
            <Switch>
              <ProtectedRoute
                key="0"
                exact
                path="/"
                render={() => <Redirect to="/atas" />}
              />
              <ProtectedRoute
                key="1"
                path="/atas"
                exact
                component={() => <Atas />}
              />
              <ProtectedRoute
                key="2"
                path="/denuncias"
                exact
                component={() => <Denuncias />}
              />
              <ProtectedRoute
                key="3"
                path="/providencias"
                exact
                component={() => <Providencias />}
              />
              <ProtectedRoute
                key="4"
                path="/notas"
                exact
                component={() => <Notas />}
              />
              <ProtectedRoute
                key="5"
                path="/prestacao"
                exact
                component={() => <Prestacao />}
              />
              <ProtectedRoute
                key="6"
                path="/demandas"
                exact
                component={() => <Demandas />}
              />
              <ProtectedRoute
                key="7"
                path="/resolucoes"
                exact
                component={() => <Resolucoes />}
              />
              <ProtectedRoute
                key="8"
                path="/administracao"
                exact
                component={() => <Administracao />}
              />
              <ProtectedRoute
                key="9"
                path="/historico"
                exact
                component={() => <Historico />}
              />
              <ProtectedRoute
                key="10"
                path="/perfis"
                exact
                component={() => <Perfil />}
              />
            </Switch>
          </Content>
        </Layout>
      </Layout>

      <GlobalStyle />
    </>
  )
}

export default App
