import React, { useState, useEffect, useCallback } from 'react'
import Helmet from 'react-helmet'
import moment from 'moment'
import locale from 'antd/es/date-picker/locale/pt_BR'
import { pdf } from '@react-pdf/renderer'
import MyDocument from 'services/pdf'

// Material UI
import {
  Avatar,
  Divider,
  Tooltip,
  PageHeader,
  Table,
  Space,
  Button,
  ConfigProvider,
  Input,
  Modal,
  Typography,
  Layout,
  DatePicker,
  Form,
  Select,
  Alert,
  Upload,
  message,
  Descriptions
} from 'antd'
import {
  PlusCircleOutlined,
  PaperClipOutlined,
  ExclamationCircleOutlined,
  PlusSquareOutlined,
  UploadOutlined,
  DeleteOutlined,
  InboxOutlined,
  UserOutlined,
  EditOutlined,
  PrinterOutlined
} from '@ant-design/icons'

import { TypeAta, MenuActions, ActionItem } from 'styles/global'

// Componentes
import TableEmpty from 'components/TableEmpty'
import api from 'services/api'
import { useAuth } from 'hooks/useAuth'

const { Text } = Typography
const { confirm } = Modal
const { Option } = Select
const { Sider, Content } = Layout
const { TextArea } = Input
const { Dragger } = Upload

function Atas() {
  const selectionType = 'checkbox'
  const route = 'ata'
  const { user } = useAuth()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [load, setLoad] = useState(false)
  const [selectedData, setSelectedData] = useState()
  const [details, setDetails] = useState(null)
  const [filtred, setFiltred] = useState([])
  const [atas, setAtas] = useState([])
  const [users, setUsers] = useState([])
  const [demandas, setDemandas] = useState([])
  const [search, setSearch] = useState('')
  const [modal, setModal] = useState('nenhum') // modais: nenhum, tipo, nova_ata, detail, upload_ata,
  const [page, setPage] = useState(1)
  const [value, setValue] = useState('')
  const [data, setData] = useState([])
  const [anexos, setAnexos] = useState([])
  const [loadDocuments, setLoadDocuments] = useState([])
  const [dataSource, setDataSource] = useState(data)
  const [pagination, setPagination] = useState({
    total: 50,
    per_page: 1,
    current_page: 1,
    last_page: 99,
    first_page: 1,
    first_page_url: '/?page=1',
    last_page_url: '',
    next_page_url: '',
    previous_page_url: null
  })

  // Listar atas
  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true)
        await refreshData()
        await api
          .get(`/usuario?conselhoId=${user.conselhoId}`)
          .then(({ data }) => {
            setUsers(data)
          })
        await api
          .get(`/demanda?conselhoId=${user.conselhoId}`)
          .then(({ data }) => {
            setDemandas(data)
          })
      } catch {
        message.error('Erro ao carregar os dados, atualize a página')
      } finally {
        setLoading(false)
      }
    }
    loadData()
  }, [load, page, value])

  const refreshData = async () => {
    if (value !== '') {
      console.log(value)
      await api
        .get(
          `/${route}?conselhoId=${user.conselhoId}&page=${page}&search=${value}`
        )
        .then(({ data }) => {
          setPagination({ ...data.meta })
          setDataSource(data.data)
        })
    } else {
      await api
        .get(`/${route}?conselhoId=${user.conselhoId}&page=${page}`)
        .then(({ data }) => {
          setPagination({ ...data.meta })
          setDataSource(data.data)
          setData(data)
        })
    }
  }

  const onClose = () => {
    form.resetFields()
    setAnexos('')
    setModal('nenhum')
    setLoadDocuments([])
  }

  const deleteData = id => {
    confirm({
      title: 'Você deseja realmente excluir?',
      icon: <ExclamationCircleOutlined />,
      content: 'Essa ação é irreversível após a confirmação',
      okText: 'Confirmar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk: async () => {
        await api
          .delete(`/${route}/${id}`)
          .then(() => {
            refreshData()
            onClose()
            setLoad(!load)
          })
          .catch(() => {
            message.error('Erro ao excluir essa Ata')
          })
      }
    })
  }

  const storeData = async () => {
    if (anexos) {
      form.setFieldsValue({ ...form, anexos })
    }
    const data = await form.validateFields()
    console.log(data)
    await form.validateFields().then(async values => {
      modal === 'editar_ata'
        ? await api
            .put(
              `/${route}/${details.id}?conselhoId=${user.conselhoId}`,
              values
            )
            .then(() => {
              refreshData()
              onClose()
              message.success('Ata editada com sucesso!')
              setLoad(!load)
            })
            .catch(e => {
              if (e.response.data) {
                if (e.response.data.errors) {
                  e.response.data.errors.forEach(e => message.error(e.message))
                } else {
                  message.error(e.response.data.error)
                }
              } else {
                message.error('Erro ao editar, tente novamente')
              }
            })
        : await api
            .post(`/${route}?conselhoId=${user.conselhoId}`, values)
            .then(() => {
              message.success('Nota cadastrada com sucesso!')
              refreshData()
              onClose()
              setLoad(!load)
            })
            .catch(e => {
              if (e.response.data) {
                if (e.response.data.errors) {
                  e.response.data.errors.forEach(e => message.error(e.message))
                } else {
                  message.error(e.response.data.error)
                }
              } else {
                message.error('Erro ao cadastrar, tente novamente')
              }
            })
    })
  }

  const upload = {
    name: 'file',
    multiple: false,
    action: `${process.env.REACT_APP_API}/upload`,
    method: 'POST',
    data: { pasta: 'atas' },
    onChange(info) {
      const { status } = info.file
      if (status === 'done') {
        message.success(`${info.file.name} sucesso ao fazer upload.`)
        form.setFieldsValue({ arquivo: info.file.response.url })
      } else if (status === 'error') {
        message.error(`${info.file.name} falha ao fazer upload.`)
      }
    }
  }

  const multiUpload = {
    name: 'file',
    multiple: true,
    action: `${process.env.REACT_APP_API}/upload`,
    method: 'POST',
    data: { pasta: 'teste' },

    onChange(info) {
      const { status } = info.file
      if (status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (status === 'done') {
        message.success(`${info.file.name} sucesso ao fazer upload.`)
        setAnexos([
          ...anexos,
          { anexo: info.file.response.url, fileName: info.file.name }
        ])
      } else if (status === 'error') {
        message.error(`${info.file.name} falha ao fazer upload.`)
      }
    },
    onRemove(file) {
      api.delete(`documento/${file.uid}`).then(() => {
        message.success('Arquivo deletado com sucesso.')
      })
    }
  }

  const rowSelection = {
    onChange: selectedRowKeys => {
      setSelectedData(selectedRowKeys)
    }
  }

  const columns = [
    {
      title: 'Número da ATA',
      dataIndex: 'numero',
      key: 'numero'
    },
    {
      title: 'Data',
      dataIndex: 'data',
      key: 'data',
      defaultSortOrder: 'descend',
      sorter: (a, b) => new Date(a.data) - new Date(b.data),
      render: text => moment(text).format('DD/MM/YYYY HH:mm')
    },
    {
      title: 'Participantes',
      dataIndex: 'participantes',
      key: 'participantes',
      render: text => (
        <Avatar.Group maxCount={3} size="medium">
          {text.map(item =>
            item.foto ? (
              <Tooltip title={item.nome} placement="top" key={item.id}>
                <Avatar src={item.foto} />
              </Tooltip>
            ) : (
              <Tooltip title={item.nome} placement="top" key={item.id}>
                <Avatar
                  style={{ backgroundColor: '#143A7F' }}
                  icon={<UserOutlined />}
                />
              </Tooltip>
            )
          )}
        </Avatar.Group>
      )
    },
    {
      title: 'Ações',
      dataIndex: 'acoes',
      key: 'acoes',
      render: (_, record) => (
        <Space size="middle">
          <a
            onClick={() => {
              setModal('detail')
              setDetails(record)
              console.log(record)
              setLoadDocuments(
                record.documento.map(doc => {
                  return {
                    uid: doc.id,
                    url: doc.url,
                    name: doc.file_name
                  }
                })
              )
            }}
          >
            detalhes
          </a>
          <a
            style={{ color: 'red' }}
            onClick={() => {
              deleteData(record.id)
            }}
          >
            excluir
          </a>
        </Space>
      )
    }
  ]

  const props = {
    showUploadList: {
      showDownloadIcon: true,
      downloadIcon: 'download',
      showRemoveIcon: true
    },
    onRemove(file) {
      api.delete(`documento/${file.uid}`).then(() => {
        message.success('Arquivo deletado com sucesso.')
      })
    }
  }

  const saveBlob = (blob, filename) => {
    const a = document.createElement('a')
    document.body.appendChild(a)
    a.style.display = 'none'
    const url = window.URL.createObjectURL(blob)
    a.href = url
    a.download = filename
    a.click()
    window.URL.revokeObjectURL(url)
  }

  const renderPdf = async (data, filename) => {
    saveBlob(await pdf(<MyDocument dados={data} />).toBlob(), filename)
  }

  return (
    <div>
      <Helmet>
        <title>Atas de Reunião – SETASC</title>
      </Helmet>

      {/* Modal de Detalhes */}
      <Modal
        visible={modal === 'detail'}
        title="Ata de Reunião"
        footer={[]}
        onCancel={onClose}
        width={850}
      >
        {details && (
          <>
            <Descriptions>
              <Descriptions.Item label="Número da ATA">
                <p style={{ color: '#00000050' }}>{details.numero}</p>
              </Descriptions.Item>
              <Descriptions.Item label="Data">
                <p style={{ color: '#00000050' }}>
                  {moment(details.data).format('DD/MM/YYYY HH:mm')}
                </p>
              </Descriptions.Item>
              <Descriptions.Item label="Participantes">
                <Avatar.Group
                  maxCount={3}
                  size="medium"
                  maxStyle={{ color: '#FFFFFF', backgroundColor: '#16407D' }}
                >
                  {details.participantes.map(item =>
                    item.foto ? (
                      <Tooltip
                        title={item.nome}
                        placement="top"
                        key={item.nome}
                      >
                        <Avatar src={item.foto} />
                      </Tooltip>
                    ) : (
                      <Tooltip
                        title={item.nome}
                        placement="top"
                        key={item.nome}
                      >
                        <Avatar
                          style={{ backgroundColor: '#143A7F' }}
                          icon={<UserOutlined />}
                        />
                      </Tooltip>
                    )
                  )}
                </Avatar.Group>
              </Descriptions.Item>
            </Descriptions>
            <MenuActions>
              <ActionItem
                onClick={() => {
                  form.setFieldsValue({
                    ...details,
                    data: moment(details.data),
                    demandas: details.demandas.map(item => item.id),
                    participantes: details.participantes.map(item => item.id)
                  })
                  setModal('editar_ata')
                }}
              >
                <EditOutlined />
                <p>Editar</p>
              </ActionItem>
              <ActionItem
                onClick={() => {
                  renderPdf(details, details.numero)
                }}
              >
                <PrinterOutlined />
                <p>Imprimir</p>
              </ActionItem>
              <ActionItem
                style={{ color: 'red' }}
                onClick={() => {
                  deleteData(details.id)
                }}
              >
                <DeleteOutlined />
                <p>Excluir</p>
              </ActionItem>
            </MenuActions>
            <Divider />
            {details.arquivo ? (
              <>
                <Text>Registro através do upload!</Text>
                <br />
                <br />
                <a href={details.arquivo} target="_blank" rel="noreferrer">
                  <Text style={{ color: '#1890FF', cursor: 'pointer' }}>
                    <PaperClipOutlined /> Visualizar arquivo
                  </Text>
                </a>
              </>
            ) : (
              <div dangerouslySetInnerHTML={{ __html: details.registro }} />
            )}
            <Upload
              {...props}
              listType="picture"
              defaultFileList={loadDocuments}
            ></Upload>
          </>
        )}
      </Modal>

      {/* Modal Escolher Tipo  */}
      <Modal
        visible={modal === 'tipo'}
        destroyOnClose
        title="Tipo de ata"
        footer={[]}
        onCancel={onClose}
      >
        <TypeAta>
          <div onClick={() => setModal('nova_ata')}>
            <PlusSquareOutlined
              style={{
                fontSize: '34px',
                color: '#258FFB',
                paddingBottom: '20px'
              }}
            />
            <h3>Nova Ata</h3>
            <Text type="primary">Escrever uma nova ata</Text>
          </div>
          <div onClick={() => setModal('upload_ata')}>
            <UploadOutlined
              style={{
                fontSize: '34px',
                color: '#258FFB',
                paddingBottom: '20px'
              }}
            />
            <h3>Upload de Ata</h3>
            <Text type="primary">Subir uma Ata já escrita</Text>
          </div>
        </TypeAta>
      </Modal>

      {/* Modal Novo ou Editar */}
      <Modal
        style={{ top: 30 }}
        visible={modal === 'nova_ata' || modal === 'editar_ata'}
        title="Nova ata de reunião"
        destroyOnClose
        footer={[
          <Button key="back" onClick={onClose}>
            Cancelar
          </Button>,
          <Button key="confirm" type="primary" onClick={storeData}>
            Confirmar
          </Button>
        ]}
        onCancel={onClose}
        width={1000}
        className="modal-new-ata"
      >
        <Form
          layout="vertical"
          form={form}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Layout>
            <Sider
              style={{ padding: 25, borderRight: '1px solid #F0F0F0' }}
              width="300"
            >
              <Form.Item label="Número de Ata" name="numero">
                <Input size="large" disabled placeholder="Número Automático" />
              </Form.Item>
              <Form.Item
                label="Data"
                name="data"
                rules={[
                  {
                    required: true,
                    message: 'Informe a data'
                  }
                ]}
              >
                <DatePicker
                  placeholder="Selecione a data"
                  size="large"
                  style={{ width: '100%' }}
                  locale={locale}
                  format="DD/MM/YYYY HH:mm"
                  showTime={{ defaultValue: moment('08:00', 'HH:mm') }}
                />
              </Form.Item>

              <Form.Item label="Participantes" name="participantes">
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Selecione os participantes"
                  size="large"
                  optionLabelProp="label"
                >
                  {users.map(item => (
                    <Option key={item.id} value={item.id} label={item.nome}>
                      {item.nome}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item>
                <Alert
                  message="Os participantes serão notificados para confirmar a participação"
                  type="info"
                  showIcon
                />
              </Form.Item>
              <Form.Item label="Demandas" name="demandas">
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Selecione as demandas"
                  size="large"
                >
                  {demandas.map(item => (
                    <Option key={`demanda${item.id}`} value={item.id}>
                      {item.titulo}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Incluir anexos:" name="anexos">
                <Dragger {...multiUpload} defaultFileList={loadDocuments}>
                  <p className="ant-upload-text">
                    Clique ou arraste o(s) arquivo(s)
                  </p>
                  <p className="ant-upload-hint">
                    Upload de um ou mais arquivo(s)
                  </p>
                </Dragger>
              </Form.Item>
            </Sider>
            <Content style={{ padding: 25 }}>
              <Form.Item
                label="Registro da Reunião"
                name="registro"
                rules={[
                  {
                    required: true,
                    message: 'Escreva algo sobre a reunião'
                  }
                ]}
              >
                <TextArea
                  size="large"
                  placeholder="Descreva sobre a reunião"
                  autoSize={{ minRows: 22, maxRows: 22 }}
                  style={{ height: '-webkit-fill-available' }}
                />
              </Form.Item>
            </Content>
          </Layout>
        </Form>
      </Modal>

      {/* Modal Novo Upload */}
      <Modal
        visible={modal === 'upload_ata'}
        title="Nova ata de reunião"
        destroyOnClose
        footer={[
          <Button key="back" onClick={onClose}>
            Cancelar
          </Button>,
          <Button key="submit" type="primary" onClick={storeData}>
            Confirmar
          </Button>
        ]}
        onCancel={onClose}
        width={700}
        className="modal-upload-ata"
      >
        <Form
          layout="vertical"
          form={form}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          style={{ width: '100%' }}
          initialValues={{
            registro: 'Registro através de upload'
          }}
        >
          <Form.Item
            name="arquivo"
            rules={[
              {
                required: true,
                message: 'Adicione um arquivo'
              }
            ]}
          >
            <Dragger {...upload}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Clique ou arraste o arquivo</p>
              <p className="ant-upload-hint">Upload de um único arquivo</p>
            </Dragger>
          </Form.Item>
          <Form.Item label="Registro da Reunião" name="registro">
            <TextArea style={{ display: 'none' }} />
          </Form.Item>
          <Space size="large" align="top" style={{ width: '100%' }}>
            <div style={{ width: '100%' }}>
              <Form.Item label="Número de Ata" name="numero">
                <Input placeholder="Número Automático" size="large" disabled />
              </Form.Item>
              <Form.Item
                label="Data"
                name="data"
                rules={[
                  {
                    required: true,
                    message: 'Informe a data'
                  }
                ]}
              >
                <DatePicker
                  placeholder="Selecione a data"
                  size="large"
                  style={{ width: '100%' }}
                  locale={locale}
                  format="DD/MM/YYYY HH:mm"
                  showTime={{ defaultValue: moment('08:00', 'HH:mm') }}
                />
              </Form.Item>
            </div>
            <div style={{ width: '100%' }}>
              <Form.Item label="Participantes" name="participantes">
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Selecione os participantes"
                  size="large"
                >
                  {users.map(item => (
                    <Option key={item.id} value={item.id} label={item.nome}>
                      {item.nome}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Demandas" name="demandas">
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Selecione as demandas"
                  size="large"
                >
                  {demandas.map(item => (
                    <Option key={item.id} value={item.id} label={item.label}>
                      {item.titulo}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item style={{ marginTop: '-15px' }}>
                <Text type="primary">
                  Adicione alguma demanada que foi discutida nessa reunião
                </Text>
              </Form.Item>
            </div>
          </Space>
        </Form>
      </Modal>

      <main>
        <PageHeader
          className="header-pages"
          title="Atas de Reunião"
          subTitle={`${atas.length} registros no total`}
          extra={[
            <Input.Search
              allowClear
              style={{ width: 'fit-content' }}
              key="search"
              placeholder="Pesquisar"
              onSearch={async value => {
                if (value !== '') {
                  setValue(value)
                  await refreshData()
                } else {
                  setValue('')
                  await refreshData()
                }
              }}
            />,
            <Button
              key="nova_ata"
              type="primary"
              icon={<PlusCircleOutlined />}
              size="medium"
              onClick={() => setModal('tipo')}
            >
              Nova Ata de reunião
            </Button>
          ]}
        />
        <ConfigProvider renderEmpty={TableEmpty}>
          <Table
            dataSource={dataSource}
            loading={loading}
            rowKey={record => record.id}
            rowSelection={{
              type: selectionType,
              ...rowSelection
            }}
            pagination={{
              showSizeChanger: false,
              total: pagination.total,
              current: pagination.current_page,
              pageSize: pagination.per_page,
              onChange: page => {
                setPage(page)
              }
            }}
            columns={columns}
          />
        </ConfigProvider>
      </main>
    </div>
  )
}

export default Atas
